import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowswungicon.svg'
import {ReactComponent as PeopleVenturesLogo} from './../../../assets/icons/people_ventures_logo.svg'

const FooterEle = ({data}) => (
	data.internal_external_link == 'internal' ? 
		<div className="item"><Link to={'/'+data.page[0].post_name}>{data.page[0].post_title}</Link></div>
	:
		<div className="item"><a href={data.url} target="_blank">{data.label}</a></div>
)

function Footer(props) {
  return (
  	props.footer ? 
	    <div className="PageModule Footer">
	    	<div className="col col3 left">
	    		<div className="inner">
	    			<div className="col-label">Contact</div>
	    			<div className="item" dangerouslySetInnerHTML={{__html: props.footer.menu_col2}}/>
    			</div>
    		</div>
    		<div className="col col4 center">
	    		<div className="inner">
	    			<div className="col-label">Navigation</div>
	    			{props.footer.menu_col1 ? props.footer.menu_col1.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
	    				)) : null}
    			</div>
    		</div>
    		<div className="col col3 right">
	    		<div className="inner">
    			<div className="col-label">Social Media</div>
	    			{props.footer.menu_col3 ? props.footer.menu_col3.map((ele, id) => (
	    				<FooterEle key={id} data={ele}/>
    				)) : null}
    			</div>
    		</div>
    		<div className="col col12 logo">
	    		<Link to="/"><PeopleVenturesLogo/></Link>
	    		<ul className="privacymenu">
		    		{props.footer.privacy?.map((ele, id) => (
		    				<FooterEle key={id} data={ele}/>
		    			))
		    		}
	    		</ul>
    		</div>
	    </div>
	: null
  );
}

export default connect(state => ({
	footer: state.Globals.footer
}))(Footer);
