import React, {useState, useEffect} from 'react';
import './3_PartOfMondayMeetings.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import {connect} from 'react-redux'

import {ReactComponent as Circle} from './../../../../../assets/operations/circle.svg'
import {ReactComponent as Wheel} from './../../../../../assets/operations/wheel.svg'
import {ReactComponent as Arrow1} from './../../../../../assets/operations/arrow.svg'

function PartOfMondayMeetings(props) {
  const [line1Width, setLine1Width] = useState(20000);
  const [line2Width, setLine2Width] = useState(20000);

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
        // mobile
          trigger: '#PartOfMondayMeetings',
          start: 'top center',
          end: 'bottom center',
          // markers: true,
          // pin: '#PartOfMondayMeetings .scrollwrapper',
          scrub: true,
          // onEnter: () => {
          //   console.log('set part of monday meeting as fixed', window.scrollY);
          //   var ele = document.getElementById('PartOfMondayMeetings')
          //   var ghostEle = document.createElement('div')
          //   ghostEle.style.height = '100vh';
          //   ghostEle.style.position = 'relative';
          //   var parentEle = document.getElementById('animation-container');
          //   parentEle.insertBefore(ghostEle, ele);

          //   ele.style.position = 'sticky';
          //   ele.style.top = window.scrollY+'px';
          // },
          // onLeave: () => {
          //   console.log('leaving part of monday meetings');
          //   var ele = document.getElementById('PartOfMondayMeetings')
          //   ele.style.position = 'relative';
          //   ele.style.top = '0';
          // }
      },
      desktop: {
        start: `${props.start} 0%`,
        end: "+="+props.length,
        scrub: props.isMobileSize ? 'true' : props.scrub,
        onEnter: () => props.dispatch({
          type: 'SET_ACTIVE_OPERATIONS_SECTION',
          id: 0
        })
      }
    }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })

  // opacity
    .to('#PartOfMondayMeetings', {opacity: 1, ease: 'none', duration: 0.5}, 0.5)
    .to('#PartOfMondayMeetings', {opacity: 0, ease: 'none', duration: 0.5}, 5.5)

  // background color leave
    // if (!props.isMobileSize) 
      tl.to('#animation-container', {backgroundColor: '#fff', ease: 'none', duration: 0.5}, 5.5)

  // parallax
    // if (props.isMobileSize) tl.to('#PartOfMondayMeetings .scrollwrapper', {y: '100%', ease: 'none', duration: 6}, 0)

  // textlines
    tl.to('#PartOfMondayMeetings .line1', {x: '-100%', ease: 'none', duration: 6}, 0)
    .to('#PartOfMondayMeetings .line2', {x: '100%', ease: 'none', duration: 6}, 0)


  // set line width : 1
      let width = window.innerWidth;
      var line1Elements = document.getElementsByClassName('mondaymeeting-line1')
      for (let i = 0; i < line1Elements.length; i++) width += line1Elements[i].offsetWidth
      setLine1Width(width);

  // set line width : 2
      width = window.innerWidth;
      var line2Elements = document.getElementsByClassName('mondaymeeting-line2')
      for (let i = 0; i < line2Elements.length; i++) width += line2Elements[i].offsetWidth
      setLine2Width(width);


  //   var line1Dots = document.getElementsByClassName('mondaymeeting-dot-line1')
  //   let dotWidth =line1Dots[0].offsetWidth
  // console.log('dotWidth', dotWidth);
    // for (let i = 0; i < line1Dots.length; i++) width += line1Dots[i].offsetWidth;

    // line1Elements.map((ele) => width += ele.offsetWidth)

}, [])

  return ( 
      <section id="PartOfMondayMeetings">
        <div className="scrollwrapper">
          <h1 className="line1" style={{width: line1Width}}>
            {props.data.line1.map((ele, i) => (
              <span key={i} className="mondaymeeting-line1">{ele.line}</span>
            ))}
          </h1>
          <h1 className="line2" style={{width: line2Width}}>
            {props.data.line2.map((ele, i) => (
              <span key={i} className="mondaymeeting-line2">{ele.line}</span>
            ))}
          </h1>
        </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false,
  scrollPosition: state.App.scrollPosition
}))(PartOfMondayMeetings);
