import React, {useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import {ReactComponent as IconLogo} from './../../../../assets/icons/icon-logo.svg'

function FrontpageHeader(props) {
	const videoPlayer = useRef();
	const [videoIsLoading, setVideoIsLoading] = useState(true)

	const videoCanPlay = () => {
		videoPlayer.current.play();
		setVideoIsLoading(false)
	}

  return (
  	props.data ? 
    <div className={`PageModule FrontpageHeader mod${props.num} ${props.videoIsLoading ? 'loading' : ''}`}>
    	{/*videoIsLoading ? <img src="/video_poster.jpg" className="poster"/> : null*/}
    	<video muted loop playsInline autoPlay ref={videoPlayer} onCanPlay={() => videoCanPlay()} >
    		<source src={props.isHorizontal ? props.data.video : props.data.video_mobile} type="video/mp4"/>
    	</video>
    </div>
    : null
  );
}

export default connect(state => ({
	isHorizontal: state.Device.size.width > state.Device.size.height
}))(FrontpageHeader);
