import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {getApiBase} from './../../helpers'

import {store} from './../../config/configureStore.js'

const fetchData = async (dispatch, match, routes, query) => {

	let canDataBeSet = new Promise((resolve) => {
		waitForIt();
		function waitForIt() {
			if (store.getState().App.pagedataCanBeSet) resolve(true);
			else setTimeout(waitForIt, 25)
		}
	})


	let currentRoute = routes.find(ele => '/'+ele.slug === match.path)
	let queryToAdd = '';
	if (query) {
			if (query.page) queryToAdd+='&page='+query.page;
			if (query.cat) queryToAdd+='&cat='+query.cat;
		};
	let endPointToFetch = getApiBase()+currentRoute.endpoint+queryToAdd;
	const response = await fetch(endPointToFetch);

	const jsonResponse = await response.json();
	const dataCanBeSet = await canDataBeSet;

	dispatch({
		type: 'PAGE_HAS_LOADED',
		pageObj: {
			url: match.url,
			component: currentRoute.component,
			data: jsonResponse
		}
	})
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ApiFetcher(props) {
	const receiveUrl = props.match.url;
	const prevUrl = usePrevious(receiveUrl);
	const [currentKey, setCurrentKey] = useState();

	useEffect(() => {
		if (receiveUrl !== prevUrl) {
			// set scrollposition for use with browserhistory
				props.dispatch({type: 'SET_CURRENT_SCROLL', nextRouterKey: props.router.location.key})
			
			// set pageloader
				props.dispatch({type: 'PAGE_IS_LOADING'})
			
			// fetch data
				fetchData(props.dispatch, props.match, props.routes, props.query)
		}
	}, [receiveUrl])

	return null;
}

export default connect(state => ({
	routes: state.Routes.routes,
	router: state.router,
	query: state.router.location.query
}))(ApiFetcher)