import React, {useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import {ReactComponent as IconLogo} from './../../../../assets/icons/icon-logo.svg'
import LazyImageComponent from "../../LazyImageComponent";

function FrontpageSliderImageSlide(props) {
	return (
		props.data ?
			<div className={`FrontpageSliderImageSlide ${props.init ? 'loaded' : ''} ${props.data?.people_in_front_of_logo ? 'in_front' : 'behind'}`}>
				<LazyImageComponent height={props.data?.people?.height} width={props.data?.people?.width} url={props.data?.people?.url}/>
			</div>
			: null
	);
}

export default connect(state => ({
	isHorizontal: state.Device.size.width > state.Device.size.height
}))(FrontpageSliderImageSlide);
