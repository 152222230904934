const initialState = {
	globalsHasBeenSet: false,
	menuitems: [],
	mainmenuIsOpen: false,
	resetMainMenu: false
}

const Globals = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_GLOBALS':
			return {
				...state,
				globalsHasBeenSet: true,
				menu: {
					mainmenu: {
						main: action.data.mainmenu,
						left: action.data.mainmenu_quicklinks_left,
						right: action.data.mainmenu_some_right,
					},
					topmenu: {
						left: action.data.topmenu_left,
						right: action.data.topmenu_right,
					},
				},
				footer: {
					menu_col1: action.data.link_col1,
					menu_col2: action.data.address,
					menu_col3: action.data.link_col3,
					privacy: action.data.link_privacy,
				},
				meta: {
					description_global: action.data.meta_description
				}
			}
		break;

		case 'TOGGLE_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: !state.mainmenuIsOpen,
				resetMainMenu: false
			}

		case 'OPEN_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: true,
				resetMainMenu: false
			}

		case 'CLOSE_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: false,
				resetMainMenu: false
			}

		case 'RESET_MAINMENU':
			return {
				...state,
				mainmenuIsOpen: false,
				resetMainMenu: true
			}

		default:
			return state
	}
}

export default Globals