import React from 'react';
import './App.scss';

import {connect} from 'react-redux'
import CookieBot from 'react-cookiebot'

import SiteLoader from './SiteLoader'
import PaperjsCanvas from './PaperjsCanvas'
import Globals from './Globals'
import Routes from './Routes'
import SmoothScrollHandler from './SmoothScrollHandler'
import InteractionHandler from './InteractionHandler'

const domainGroupId = '83532b2b-bcbf-4354-8432-3169a95c874d';

function App(props) {
  return (
	    <div className={`App ${props.isMobile ? 'mobile' : 'desktop'} ${props.pageIsLoading ? 'loading' : ''}`} 
	    	onMouseMove={(e) => props.dispatch({type: 'SET_CURSOR_POSITION', position: {x: e.clientX, y: e.clientY}})}
	    	// onMouseEnter={() => props.dispatch({type: 'SHOW_CURSOR', show: true})} 
      //   	onMouseLeave={() => props.dispatch({type: 'SHOW_CURSOR', show: false})} 
    	>
	    	{/*!props.isMobile ? <ScreenSaver/> : null*/}
	    	{/*<SiteLoader/>*/}
	    	<CookieBot domainGroupId={domainGroupId} />
	    	<PaperjsCanvas/>
	        <Globals/>
  			{props.routesHasBeenSet ? <Routes/> : null}
      		<SmoothScrollHandler/>
      		<InteractionHandler/>
	    </div>
  );
}

export default connect((state) => ({
	routesHasBeenSet: state.Routes.routesHasBeenSet,
	globalsHasBeenSet: state.Globals.globalsHasBeenSet,
	isMobile: state.Device.isMobile,
	pageIsLoading: state.App.pageIsLoading
}))(App);