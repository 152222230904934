import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

function Btn(props) {
  return (
  	props.type == 'external' ? 
	   	<a className={`btn magnetic ${props.size} ${props.isMobile ? 'mobile' : ''}`} href={props.url} target="_blank">
		  <div className="filler"/>
		  <div className="label" label={props.label}/>
		</a>
	: props.type == 'internal' ? 
		<Link className={`btn magnetic ${props.size} ${props.isMobile ? 'mobile' : ''}`} to={props.url}>
		  <div className="filler"/>
		  <div className="label" label={props.label}/>
		</Link>
	: props.type == 'email' ? 
		<a className={`btn magnetic ${props.size} ${props.isMobile ? 'mobile' : ''}`} href={`mailto:${props.url}`}>
		  <div className="filler"/>
		  <div className="label" label={props.label}/>
		</a>
	: null
  );
}

export default connect(state => ({
	isMobile: state.Device.isMobile
}))(Btn);
