import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link, useLocation} from 'react-router-dom'
import ArchivePeopleThumb from './../ArchivePeopleThumb'
import ArchiveFilter from './../ArchiveFilter'
import {getApiBase} from './../../../helpers'

import {setPageHeight} from './../../../actions'

function ArchivePeople(props) {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentCat, setCurrentCat] = useState(null);
  const initialLoad = useRef(true);
  let location = useLocation();

  const fetchData = async (query) => {
        setIsFetchingData(true);
        let queryToGet = '?cpt=stuff';
        queryToGet += query.cat ? '&cat='+query.cat+'&tax=people_category' : '';

        const url = getApiBase()+'/wp-json/data/v2/getarchivepeople'+queryToGet;
        const response = await fetch(url);
        const jsonResponse = await response.json();

        // replace all archivedata in redux
          props.dispatch({
            type: 'REPLACE_ARCHIVE_POSTS',
            cpt: 'people',
            data: jsonResponse,
            pageKey: props.slug
          })

        setTimeout(() => {
          setIsFetchingData(false);
          setPageHeight();
        }, 500);

        props.dispatch({
          type: 'UPDATE_ROUTER_KEY',
          key: props.currentRouterKey
        })
  }

  useEffect(() => {
    if (props.slug == props.currentRouterPathname) {
      if (initialLoad.current) initialLoad.current = false;
      else {
        if (props.query) fetchData(props.query);
      }

      setCurrentCat(props.query.cat);
    }
  }, [props.query])

  const scrollToSection = (anchor) => {


    let parentEle = document.getElementsByClassName('ArchivePeople')[0];
    let ele = document.getElementsByClassName(anchor)[0];
    let scrollPos = ele.offsetTop + parentEle.offsetTop;

    window.scrollTo(0, scrollPos - 21);

    // props.dispatch({
    //  type: 'SET_SCROLL_POSITION',
    //  scrollPosition: 200
    // })
  }

  return (
  	props.data ?
    <div className={`PageModule ArchivePeople mod${props.num}`}>
        <div className="col col10 submenu">
          <div className="title">Filter by category</div>
          <div className="filter-tags">
            <div className="filter-tags-single active" onClick={() => scrollToSection('section-splitter-team')}>Team</div>
            <div className="filter-tags-single" onClick={() => scrollToSection('section-splitter-founders')}>Founders</div>
          </div>
        </div>
        <h1 className="col col10 section-splitter section-splitter-team">Team</h1>
        <div className="col col10 results">
        	<div className={`inner archivepeopleinner ${isFetchingData ? 'loading' : ''}`}>
            {props.moduleData?.postsData.posts.map((ele, i) => {
                return ele.type.slug == 'people' && !ele.hide_from_archive ? <ArchivePeopleThumb data={ele} key={i} debug={i == 0 ? true : false}/> : null
              })}
          </div>
    	</div>
      <h1 className="col col10 section-splitter section-splitter-founders">Founders</h1>
        <div className="col col10 results">
          <div className={`inner archivepeopleinner ${isFetchingData ? 'loading' : ''}`}>
            {props.moduleData?.postsData.posts.map((ele, i) => {
                return ele.type.slug == 'founders' && !ele.hide_from_archive ? <ArchivePeopleThumb data={ele} key={i} debug={i == 0 ? true : false}/> : null
              })}
          </div>
      </div>
    </div>
    : null
  );
}

export default connect((state) => ({
    query: state.router.location.query,
    currentRouterPathname: state.router.location.pathname,
    currentRouterKey: state.router.location.key,
}))(ArchivePeople);
