import {MathUtils} from './../../helpers'

export default class ParallaxItem {
	constructor(ele) {
		this.DOM = {
			ele: ele,
			inner: ele.querySelector('.inner'),
			// innerImg: ele.querySelector('.lazy-image-component img')
			innerImg: ele.querySelector('.parallax-img')
		};

		this.values = {
			previous: 0,
			current: 0,
			ease: 0.2,
			offsetSizeThumb: ele.dataset.parallaxamount ? ele.dataset.parallaxamount : 50,
    		offsetSpeedThumb: ele.dataset.parallaxspeed ? ele.dataset.parallaxspeed : 1,
      		offsetSizeImage: this.DOM.innerImg?.dataset.parallaxamount ? this.DOM.innerImg.dataset.parallaxamount : -50,
    		offsetSpeedImage: 0.5,
    		imageScale: ele.dataset.imagescale ? ele.dataset.imagescale : 1.1,
		}

		this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
            });
        this.observer.observe(this.DOM.ele);
	}

	render() {
		var offsetAlt = this.DOM.ele.getBoundingClientRect().top
        var val = (window.innerHeight/2 - offsetAlt-this.DOM.ele.offsetHeight/2)/(window.innerHeight+this.DOM.ele.offsetHeight)*-2;
        this.values.current = val;

      this.values.previous = MathUtils.lerp(this.values.previous, this.values.current, this.values.ease)
      this.DOM.inner.style.transform = `translate3d(0,${this.values.previous*this.values.offsetSizeThumb*this.values.offsetSpeedThumb}px,0)`; // thumb offset
      if (this.DOM.innerImg) this.DOM.innerImg.style.transform = `scale(${this.values.imageScale}) translate3d(0,${this.values.previous*this.values.offsetSizeImage*this.values.offsetSpeedImage}px,0)`; // image offset;

	}
}