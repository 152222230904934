import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Helmet} from 'react-helmet'

function SEO(props) {
  return (
    props.pathname !== '/' && props.pathname == props.dataKey ? 
	    <Helmet>
	      <title>People Ventures ∙ {props.title}</title>
	      <meta name="description" content={props.description ? props.description : props.meta.description_global}/>
	    </Helmet>
    : 
	    <Helmet>
	      <title>People Ventures</title>
	      <meta name="description" content={props.description ? props.description : props.meta.description_global}/>
	    </Helmet>
  );
}

export default connect((state) => ({
  	meta: state.Globals.meta,
  	pathname: state.router.location.pathname,
}))(SEO);
