import React, {useState, useEffect} from 'react';
import './4_HelpfullVentureDeveloper.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {connect} from 'react-redux'

import {ReactComponent as FlowerShape} from './../../../../../assets/operations/polygon_7.svg'

function HelpfullVentureDeveloper(props) {

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
      // mobile
        trigger: '#HelpfullVentureDeveloper',
        start: 'top center',
        end: 'bottom 65%',
        scrub: true,
    },
    desktop: {
      start: `${props.start} 0%`,
      end: "+="+props.length,
      // scrub: props.scrub,
        scrub: props.isMobileSize ? 'true' : props.scrub,
      onEnter: () => props.dispatch({
        type: 'SET_ACTIVE_OPERATIONS_SECTION',
        id: 1
      })
    }
  }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })
    
  // opacity
    .to('#HelpfullVentureDeveloper', {opacity: 1, ease: 'none', duration: 0.5}, 0)
    .to('#HelpfullVentureDeveloper', {opacity: 0, ease: 'none', duration: 0.5}, 3.5)

  // background color - leave
    // if (!props.isMobileSize) 
      tl.to('#animation-container', {backgroundColor: '#75BFB0', ease: 'none', duration: 0.5}, 3.5)
  
  // position
    if (props.isMobileSize) {
      tl.fromTo('#HelpfullVentureDeveloper .text', {y: 40}, {y: -40, ease: 'none', duration: 4}, 0)
    } else {
      tl.fromTo('#HelpfullVentureDeveloper .text', {y: 80}, {y: -80, ease: 'none', duration: 4}, 0)
    }

  // yellow svg
    tl.to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(1)', {y: '-100%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 0)
    .to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(2)', {x: '-95%', y: '-50%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 0.5)
    .to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(3)', {x: '-95%', y: '50%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 1)
    .to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(4)', {y: '100%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 1.5)
    .to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(5)', {x: '95%', y: '50%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 2)
    .to('#HelpfullVentureDeveloper .HelpfullVentureDeveloperSVG:nth-child(6)', {x: '95%', y: '-50%', rotate: 180, fill: '#75BFB0', ease: 'none', duration: 1}, 2.5)

}, [])

  return ( 
      <section id="HelpfullVentureDeveloper" className="mechanics">
          <div className="col text">
            <div className="inner">
              <h2 className="title">{props.data.headline}</h2>
              <div className="content" dangerouslySetInnerHTML={{__html: props.data.text}}/>
            </div>
          </div>
          <div className="col graphics">
            <div className="inner">
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
              <FlowerShape className="HelpfullVentureDeveloperSVG mechanicsSVG"/>
            </div>
          </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(HelpfullVentureDeveloper);
