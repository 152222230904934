import React, {useState, useEffect} from 'react';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import Btn from './../../Btn'
import ColorBoxWrapper from './../ColorBoxWrapper'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowCircleRight} from './../../../../assets/icons/icon_arrowcircle_right.svg'
import {ReactComponent as ArrowCircleLeft} from './../../../../assets/icons/icon_arrowcircle_left.svg'

function FounderSpotlightCarousel(props) {
  const [isMobile, setIsMobile] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(0);
  const [slideDir, setSlideDir] = useState();
  const [currentSlideIsAnimating, setCurrentSlideIsAnimating] = useState(false);
  const [heightOfWrapper, setHeightOfWrapper] = useState(0);
  const [currentSlideMobile, setCurrentSlideMobile] = useState(0);

  const moveSlider = (amount) => {
    setActiveSlide((prevState) => {
      setCurrentSlideIsAnimating(true)
      setPrevSlide(prevState);
      setSlideDir(amount > 0 ? 'dir-next' : 'dir-prev')
      let newSlideNum = prevState + amount;
      if (newSlideNum >= props.data.founders.length) newSlideNum = 0;
      else if (newSlideNum < 0) newSlideNum = props.data.founders.length-1
      return (newSlideNum);
    })

    setTimeout(() => {
      setCurrentSlideIsAnimating(false)
    }, 100)
  }

  useEffect(() => {
    var tallestHeight = 0;
    var elements = document.querySelectorAll('.FounderSpotlightCarousel .desktopslide.left').forEach((item) => {
      if (item.offsetHeight > tallestHeight) tallestHeight = item.offsetHeight;
    })

    setHeightOfWrapper(tallestHeight);

    setIsMobile(props.size.width <= 1050 ? true : false)
  }, [props.size])

  const sliderSettings = {
    dots: false,
    infinite: false, 
    speed: 300,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlideMobile(Math.ceil(next))
  };


  return (
  	props.data ? 
    <div className={`PageModule FounderSpotlightCarousel mod${props.num}`}>
        {!isMobile ? 
          <ColorBoxWrapper color={props.data.backgroundcolor} innerHeight={heightOfWrapper}>
            <div className="col col5 text">
          	<div className="inner">
              {props.data.founders.map((ele, index) => (
                <div key={index} className={`desktopslide left ${activeSlide === index ? 'active '+slideDir : ''} ${activeSlide === index && currentSlideIsAnimating ? 'animating' : ''} ${prevSlide === index ? 'prev '+slideDir : ''}`}>
                  <h2 className="title">{props.data.founders[index].headline}</h2>
                  <p className="description">{props.data.founders[index].description}</p>
                  <Btn url={`/people/${props.data.founders[index].link[0].post_name}`} label={props.data.founders[index].button_label} type="internal"/>
                </div>
                ))}
            </div>
      	   </div>
          <div className="col col4 image">
              <div className={`inner`}>
              {props.data.founders.map((ele, index) => (
                <div key={index} className={`parallaxcontainer desktopslide right ${activeSlide === index ? 'active '+slideDir : ''} ${activeSlide === index && currentSlideIsAnimating ? 'animating' : ''} ${prevSlide === index ? 'prev '+slideDir : ''}`} data-parallaxamount={200} data-parallaxspeed={1}>
                  <div className="inner">
                    <LazyImageComponent height={props.data.founders[index].image.sizes['medium_large-height']} width={props.data.founders[index].image.sizes['medium_large-width']} url={props.data.founders[index].image.sizes['medium_large']} imgClassName="parallax-img"/>
                  </div>
                </div>
              ))}
          </div>
          </div>
         <div className="col col2 slider-navigation">
          <ArrowCircleLeft onClick={() => moveSlider(-1)} className="carousselarrow prev magnetic"/>
          <ArrowCircleRight onClick={() => moveSlider(1)} className="carousselarrow next magnetic"/>
         </div>
        </ColorBoxWrapper>
        : 
            <Slider {...sliderSettings}>
              {props.data.founders.map((ele, i) => (
                <div className={`slide ${i === currentSlideMobile ? 'active' : ''}`} key={i}>
                  <div className="inner">
                      <LazyImageComponent height={ele.image.sizes['medium_large-height']} width={ele.image.sizes['medium_large-width']} url={ele.image.sizes['medium_large']} imgClassName="parallax-img"/>
                      <h2 className="title">{ele.headline}</h2>
                      <Btn url={`/people/${props.data.founders[i].link[0].post_name}`} label={ele.button_label} type="internal"/>
                  </div>
                </div>
              ))}
            </Slider>
        }
        <div className="mobile-background"/>
    </div>
    : null
  );
}

export default connect(state => ({
  size: state.Device.size
}))(FounderSpotlightCarousel);
