import React, {useState, useEffect} from 'react';
import './8_Gallery.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import {connect} from 'react-redux'

import {ReactComponent as GearSmall} from './../../../../../assets/operations/gear_small.svg'
import {ReactComponent as GearLarge} from './../../../../../assets/operations/gear_large.svg'
import {ReactComponent as Stick} from './../../../../../assets/operations/stick.svg'
import {ReactComponent as QuoteMark} from './../../../../../assets/operations/quotemark.svg'


function Gallery(props) {

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
      // mobile
        trigger: '#Gallery',
        start: 'top center',
        end: 'bottom 80%',
        scrub: true,
        // pin: '#Gallery',
    },
    desktop: {
      start: `${props.start} 0%`,
      end: "+="+props.length,
      // scrub: props.scrub,
      scrub: props.isMobileSize ? 'true' : props.scrub,
      onEnter: () => props.dispatch({
        type: 'SET_ACTIVE_OPERATIONS_SECTION',
        id: 3
      })
    }
  }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })
    
// background + fixed menu color
    tl.to('#animation-container', {backgroundColor: '#16182F', ease: 'none', duration: 1},0)
    .to('.StickyMenu .menu-item', {color: '#fff', ease: 'none', duration: 1},0)
    .to('.StickyMenu .menu-item .activearrow path', {fill: '#fff', ease: 'none', duration: 1},0)
  
  // position
    let dlyLeave = 0;

    for (let i = 1; i < props.items.length+1; i++) {
      let dlyEnterImage = (i-1)*2;
      let dlyEnterQuote = (i-1)*2+1.3;
      dlyLeave = (i-1)*2+1.8+1.3;
      tl.fromTo(`#Gallery img:nth-child(${i})`, {'clip-path': 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'}, {'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', ease: 'none', duration: 2}, dlyEnterImage)
      tl.fromTo(`#Gallery .single-quote:nth-child(${i})`, {y: props.isMobileSize ? '120' : '60'}, {y: props.isMobileSize ? '0' : '-60', ease: 'none', duration: 2}, dlyEnterQuote)
      tl.fromTo(`#Gallery .single-quote:nth-child(${i})`, {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.2}, dlyEnterQuote)
      tl.to(`#Gallery .single-quote:nth-child(${i})`, {opacity: 0, ease: 'none', duration: 0.2}, dlyLeave)
    }

  // opacity
    tl.to('#Gallery', {opacity: 1, ease: 'none', duration: 0.5}, 0)
    .to('#Gallery', {opacity: 0, ease: 'none', duration: 0.5}, dlyLeave-0.3)

  // background leave
    tl.to('#animation-container', {backgroundColor: '#FFC500', ease: 'none', duration: 0.5},dlyLeave-0.3)

  // if (props.isMobileSize) {
  //   tl.to('#Gallery .col.images', {y: 200, ease: 'none', duration: dlyLeave}, 0)
  //   tl.to('#Gallery .col.text', {y: 150, ease: 'none', duration: dlyLeave}, 0)
  // }

}, [])

  return ( 
      <section id="Gallery">
          <div className="col text">
            <div className="inner">
              {props.items.map((ele, i) => (
               <div className={`single-quote`} key={i}><QuoteMark className="quotemark"/><p>{ele.quote}</p></div>   
              ))}
            </div>
          </div>
          <div className="col images">
            <div className="inner">
              {props.items.map((ele, i) => (
               <img src={ele.image.sizes.medium_large} className={`single-image`} key={i}/>
              ))}
            </div>
          </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(Gallery);
