import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'
import ArchiveContentThumb from './../ArchiveContentThumb'

function SingleContentRelated(props) {
  return (
    props.related?.length > 0 ?
      <div className={`PageModule SingleContentRelated`}>
        <h4 className="title">Related Content</h4>
        <div className="list">
  	      {props.related?.map((ele, i) => {
  	        return <ArchiveContentThumb key={i} post_name={ele.post_name} image_thumb={ele.image_thumb} post_title={ele.post_title} tags={ele.tags} width="3"/>
  	      })}
  	    </div>
      </div>
    : null
  );
}

export default connect()(SingleContentRelated);
