import React from 'react';
import './index.scss';

function ColorBoxWrapper(props) {
  return (
    <div className={`ColorBoxWrapper bg-${props.color}`}>
        <div className="ColorBoxWrapperInner" style={{height: props.innerHeight ? props.innerHeight : 'inherit'}}>
        	{props.children}
    	</div>
    </div>
  );
}

export default ColorBoxWrapper;
