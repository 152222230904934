import {MathUtils} from './../../helpers'
import {store} from './../../config/configureStore'

export default class MagneticMouseItem {
	constructor(ele) {
		this.DOM = {
			ele: ele,
		};

		this.state = {
			hover: false
		}

		this.power = ele.dataset.magneticpower ? ele.dataset.magneticpower : 0.3;

		this.scrollListen = ele.dataset.magneticscrolllisten ? ele.dataset.magneticscrolllisten : true;

		this.offset = {
			top: null,
			left: null
		}

		this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.1},
            ty: {previous: 0, current: 0, amt: 0.1}
        };

        setTimeout(() => { // timeout for calculating dom position after dom is loaded
	        this.calcPos();
	        this.initEvents();

			this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
            });

	        this.observer.observe(this.DOM.ele);
        }, 50)


        this.DOM.ele.addEventListener('mouseenter', () => this.enter())
        this.DOM.ele.addEventListener('mouseleave', () => this.leave())
        this.DOM.ele.addEventListener('mousemove', () => this.movingMouse())
	}

	initEvents() {
        this.onResize = () => this.calcPos();
		window.addEventListener('resize', this.onResize)
	}

	calcPos() {
		this.rect = this.DOM.ele.getBoundingClientRect();
		let scrollX = this.scrollListen == true ? window.scrollX : 0;
		let scrollY = this.scrollListen == true ? window.scrollY : 0;

		this.offset.top = this.rect.top + scrollY;
		this.offset.left = this.rect.left + scrollX;
        this.distanceToTrigger = this.rect.width*0.7;
	}

	movingMouse() {
		let mousePos = store.getState().Cursor.position;

		let useThisScrollX = this.scrollListen == true ? window.scrollX : 0
		let useThisScrollY = this.scrollListen == true ? window.scrollY : 0

    	let x = (mousePos.x + useThisScrollX - (this.offset.left + this.rect?.width/2))*this.power;
        let y = (mousePos.y + useThisScrollY - (this.offset.top + this.rect?.height/2))*this.power;	

        this.renderedStyles['tx'].current = x;
        this.renderedStyles['ty'].current = y;
	}

	render() {
        
        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }

        this.DOM.ele.style.transform = `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0)`;


	}

	enter() {
		this.state.hover = true;
		this.DOM.ele.classList.add('active')
	}

	leave() {
		this.state.hover = false;
		this.DOM.ele.classList.remove('active')

		this.renderedStyles['tx'].current = 0;
        this.renderedStyles['ty'].current = 0;
	}
}