import React, {useEffect, useState, useRef} from 'react';
import './index.scss';

import {getApiBase, MathUtils} from './../../helpers'
import {useParams, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'

import {gsap} from 'gsap'

import FrontpageHeader from './../PageModules/FrontpageHeader'
import FrontpageSlider from "../PageModules/FrontpageSlider";
import LinkModule from './../PageModules/LinkModule'
import LinkWithImage from './../PageModules/LinkWithImage'
import Pageheader from './../PageModules/Pageheader'
import FounderSpotligthCarousel from './../PageModules/FounderSpotligthCarousel'
import CasesArchive from './../PageModules/CasesArchive'
import ArchivePeople from './../PageModules/ArchivePeople'
import ArchiveSelectedPeople from './../PageModules/ArchiveSelectedPeople'
import ArchiveContent from './../PageModules/ArchiveContent'
import SelectedContent from './../PageModules/SelectedContent'
import NormalpageFullscreenImage from './../PageModules/NormalpageFullscreenImage'
import NormalpageTwoColumn from './../PageModules/NormalpageTwoColumn'
import SubmitYourPitch from './../PageModules/SubmitYourPitch'
import Contact from './../PageModules/Contact'
import Operations from './../PageModules/Operations'
import VideoGrid from './../PageModules/VideoGrid'
import FAQ from './../PageModules/FAQ'
import SEO from './../SEO'
import Footer from './../Footer'

const NoModulesInserted = ({title}) => {
  return (
      <div style={{marginTop: 200, marginBottom: 200}}><h1 style={{textAlign: 'center', marginBottom: 20}}>{title}</h1><h2 style={{textAlign: 'center'}}>no modules inserted</h2></div>
    )
}

function NormalPage(props) {
  return (
  	<div className={`page page-normalpage${props.mainmenuIsOpen ? ' open' : ''}`} /*style={{transform: `translateY(${-scrollPosPrevious.current}px)`}}*/>
      <SEO dataKey={props.dataKey} title={props.apiData.title} description={props.apiData.meta.description}/>
      	{props.apiData.pagemodules ? props.apiData.pagemodules.map((ele, i) => (
            ele.acf_fc_layout === 'frontpage_header' ? <FrontpageHeader key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'frontpage_slider' ? <FrontpageSlider key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'link' ? <LinkModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'submit_your_pitch' ? <SubmitYourPitch key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'link_with_image' ? <LinkWithImage key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'header' ? <Pageheader key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'founder_spotlight_carousel' ? <FounderSpotligthCarousel key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'cases_archive' ? <CasesArchive key={i} data={ele} moduleData={props.apiData.moduledata.CasesArchive} num={i}/> :
            ele.acf_fc_layout === 'list_of_people' ? <ArchivePeople key={i} data={ele} slug={props.dataKey} moduleData={props.apiData.moduledata.archivepeople} num={i}/> :
            ele.acf_fc_layout === 'list_of_selected_people' ? <ArchiveSelectedPeople key={i} data={ele} slug={props.dataKey} moduleData={props.apiData.moduledata.archivepeople} num={i}/> :
            ele.acf_fc_layout === 'archive_stuff' ? <ArchiveContent key={i} data={ele} slug={props.dataKey} moduleData={props.apiData.moduledata.archivestuff} num={i}/> :
            ele.acf_fc_layout === 'stuff_selected' ? <SelectedContent key={i} data={ele} slug={props.dataKey} moduleData={props.apiData.moduledata.StuffSelected} num={i}/> :
            ele.acf_fc_layout === 'normalpage_two_column' ? <NormalpageTwoColumn key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'normalpage_fullscreen_image' ? <NormalpageFullscreenImage key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'contact' ? <Contact key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'operations' ? <Operations key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'faq' ? <FAQ key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'video_grid' ? <VideoGrid key={i} data={ele} num={i}/> :
            null
        )) : <NoModulesInserted title={props.apiData.title}/>}

        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
  return ({
  	apiData: state.App.pageData[ownProps.dataKey].data,
    pathname: state.router.location.pathname,
    mainmenuIsOpen: state.Globals.mainmenuIsOpen,
    lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
    routerAction: state.router.action,
    meta: state.Globals.meta,
    scrollPosition: state.App.scrollPosition
  })
})(NormalPage);
