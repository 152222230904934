import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import Btn from './../../Btn'

import {Link} from 'react-router-dom'

function CasesArchive(props) {
  const [casesToShow, setCasesToShow] = useState([])

  useEffect(() => {
    if (props.data.layout == 'all') setCasesToShow(props.moduleData?.posts || [])
    else {
      let casesToUse = [];
      props.data.selected_cases.forEach((ele) => {
        let eleToPush = props.moduleData.posts.find((i) => i.post_name == ele.post_name)
        casesToUse.push(eleToPush);
      })
      setCasesToShow(casesToUse);
    }
  }, [props.data])

  return (
      <div className={`PageModule CasesArchive mod${props.num}`}>
  	     {casesToShow.length > 0 ? 
          <div className="col col12">
          	<div className="inner">
            	{casesToShow.map((ele, i) => {
                return(
                  <span key={i}>
                    <span className="linktocase magnetic" data-magneticpower="0.1">
                      <Link to={`/companies/${ele.post_name}`}>
                        <span className="title">{ele.post_title}</span>
                        <img src={ele.image.sizes['medium_large']}/>
                        {i != casesToShow.length - 1 ? ', ' : ''}
                      </Link>
                    </span>
                  </span>
                )})}
            </div>
      	 </div>
        : null}

        {props.data.layout === 'selected' ? 
          <Btn url="/companies" label={props.data.button_label} type="internal"/>
        : null}
      </div>
  );
}

export default connect()(CasesArchive);
