import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import {ReactComponent as IconLogo} from './../../../assets/icons/icon-logo-alt.svg'
import LinkWrapper from './../LinkWrapper'

function Mainlogo(props) {
	const [previousPathname, setPreviousPathname] = useState();
	const [currentPathname, setCurrentPathname] = useState();

	useEffect(() => {
		setPreviousPathname(currentPathname)
		setCurrentPathname(props.pathname)
	}, [props.pathname])

  return (
    props.initialPageHasLoaded ? 
    	// previousPathname == '/' && props.pageIsLoading || currentPathname == '/' && !props.pageIsLoading ? null : 
	    	<div id="Mainlogo" className={`${props.scrollDir == 'down' && props.scrollPosition > 200 ? 'hide' : previousPathname == '/' && props.pageIsLoading && props.scrollPosition < window.innerHeight || currentPathname == '/' && !props.pageIsLoading && props.scrollPosition < window.innerHeight ? 'hide' : 'show'}`}>
		    	<LinkWrapper><Link to="/" onClick={() => props.dispatch({type: 'CLOSE_MAINMENU'})}><IconLogo/></Link></LinkWrapper>
		    </div>
	    : null
  );
}

export default connect(state => ({
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	scrollDir: state.App.scrollDir,
	scrollPosition: state.App.scrollPosition,
	pathname: state.router.location.pathname,
	pageIsLoading: state.App.pageIsLoading,
	initialPageHasLoaded: state.App.initialPageHasLoaded
}))(Mainlogo);