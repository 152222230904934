import React, {useRef, useEffect, useState} from 'react';
import {connect} from 'react-redux'

import {store} from './../../config/configureStore'

import ParallaxItem from './ParallaxItem.js'
import MagneticMouseItem from './MagneticMouseItem.js'


class AnimationHandler {
	constructor(isMobile) {
		this.pageIsLoading = true;
        this.DOM = {page: document.querySelector('.page')};
		
        // set parallax items
			this.parallaxItems = [];
	        document.querySelectorAll('.parallaxcontainer').forEach(ele => this.parallaxItems.push(new ParallaxItem(ele)));


	    // set magneticMouse items
			this.magneticMouseItems = [];
			if (!isMobile) {
	        	document.querySelectorAll('.magnetic').forEach(ele => this.magneticMouseItems.push(new MagneticMouseItem(ele)));
	        }

	    // init redux
			store.subscribe(() => this.updateOnRedux(this));


        // init animation on all items
        	this.animationReq = requestAnimationFrame(() => this.render());
	}

	updateOnRedux(_this) {
		let isLoading = store.getState().App.pageIsLoading;
		if (isLoading && !_this.pageIsLoading) _this.pageIsLoading = true
		else if (!isLoading && _this.pageIsLoading) _this.pageIsLoading = false
	}

	render() {
		if (!this.pageIsLoading) {
			// render parallax items
				for (const ele of this.parallaxItems) {
					if (ele.isVisible) ele.render();
				}

			// render magneticmouse items
				for (const ele of this.magneticMouseItems) {
					if (ele.isVisible) ele.render();
				}
		}

		// loop
        	this.animationReq = requestAnimationFrame(() => this.render());
	}

	disableAnimation() {
		cancelAnimationFrame(this.animationReq)
	}
}

function InteractionHandler(props) {
	let requestRef = useRef();
	const [AnimationInstance, setAnimationInstance] = useState();
	var instance;

	useEffect(() => {
		if (AnimationInstance) AnimationInstance.disableAnimation();
		if (props.keyToShow) { // runs on every routechange
			// console.log('%c init parallax listeners', 'background: blue; color: white');
			setTimeout(() => {
				setAnimationInstance(new AnimationHandler(props.isMobile));
			}, 100);
			// if (instance) console.log(instance);
			// instance = new AnimationHandler();
		}
	}, [props.currentRouterKey, props.keyToShow])

	// const animateThumb = (timeFrame) => {
	// 	requestRef.current = requestAnimationFrame(animateThumb)
	// }

	// useEffect(() => {
	// 	requestRef.current = requestAnimationFrame(animateThumb);
	// 	return () => cancelAnimationFrame(requestRef.current);
	// }, [])

	return null;
}

export default connect((state) => {
	return ({
		keyToShow: state.App.pagesToShow[0]?.key,
		currentRouterKey: state.App.currentRouterKey,
		isMobile: state.Device.isMobile,
		pageIsLoading: state.App.pageIsLoading
	})
})(InteractionHandler);
