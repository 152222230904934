import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import './index.scss';

import {Visuals} from './visuals'
import LoaderLogo from './LoaderLogo'

function PaperjsCanvas(props) {
	const [visuals, setVisuals] = useState(new Visuals());

	// const [enterLoad, setEnterLoad] = useState(false)
	// const [loading, setLoading] = useState(false)
	// const [exitLoad, setExitLoad] = useState(false)
	const [exitLoadIsPossible, setExitLoadIsPossible] = useState(false);

	// const 	animationSpeedIn = 800,
	const 	animationSpeedIn = 800,
			animationSpeedOut = 550,
			minimalLoadscreenTime = 300;

	useEffect(() => {
		if (props.pageIsLoading) {
			setExitLoadIsPossible(false);

			props.dispatch({
				type: 'LOADER_IS_SHOWING',
				val: true
			})

			setTimeout(() => {
				// setEnterLoad(false);
				props.dispatch({
					type: 'PAGEDATA_CAN_BE_SET',
					val: true
				})
			}, animationSpeedIn)

			setTimeout(() => {
				setExitLoadIsPossible(true)
				// console.log('exit load is possible');
			}, animationSpeedIn)
		}
	},[props.pageIsLoading])

	useEffect(() => {
		if (!props.pageIsLoading && exitLoadIsPossible) {
			// console.log('reveal page');
			props.dispatch({
				type: 'REVEAL_PAGE'
			})
			
			props.dispatch({
				type: 'LOADER_IS_LEAVING',
				val: true
			})

			setTimeout(() => {
				props.dispatch({
					type: 'LOADER_IS_SHOWING',
					val: false
				})

				props.dispatch({
					type: 'LOADER_IS_LEAVING',
					val: false
				})
			}, animationSpeedOut)
		}
	}, [props.pageIsLoading, exitLoadIsPossible])

	useEffect(() => {
		visuals.init();
	}, [])

	return (
		<>
			<canvas id="visuals-canvas" resize="true" keepalive="true"/>
			<LoaderLogo/>
		</>
		)
}

export default connect(state => ({
	pageIsLoading: state.App.pageIsLoading
}))(PaperjsCanvas);