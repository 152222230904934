import paper from 'paper';
import {store} from './../../config/configureStore'


import SiteLoader from './SiteLoader'
import MainMenu from './MainMenu'

export function Visuals() {
	var tool;

	var siteLoader;
	var mainMenu
	
	this.init = () => {
		// window.onload = () => {

			// context
				paper.setup('visuals-canvas');
				tool = new paper.Tool();

			// keyboard
			/*
				tool.onKeyDown = (e) => {
					switch(e.key) {
						case '1':
							mainMenu.resetPath();
						break;

						case '2':
							siteLoader.showLoaderWithoutAnimation();
						break;

						// case '3':
						// 	this.animatePath.bottomMiddle_up();
						// break;

						// case '4':
						// 	this.animatePath.bottomSides_up();
						// break;

						// case 'a':
						// 	this.animateIn.option1();
						// break;

						// case 's':
						// 	this.animateOut.option1();
						// break;

						// case 'z':
						// 	this.resetPath();
						// break;

					}
				}
				*/

			// init all modules
				this.initModules();


			// update
				paper.view.onFrame = () => {
					this.updateModules();
				}


			// update
				paper.view.onResize = () => {
					this.updateModulesOnResize();
				}
		// }
	}

	this.initModules = () => {
		siteLoader = new SiteLoader();
		mainMenu = new MainMenu();
	}

	this.updateModules = () => {
		siteLoader.update();
		mainMenu.update();
	}

	this.updateModulesOnResize = () => {
		siteLoader.updateOnResize();
		mainMenu.updateOnResize();
	}
}