import React from 'react';
import {connect} from 'react-redux'

import './index.scss';

function LinkWrapper(props) {
  return (
    <span className={`linkwrapper ${props.className ? props.className : ''}`}
    	onMouseEnter={() => {props.dispatch({type: 'SET_CURSOR_ACTIVE', active: true})}} 
        onMouseLeave={() => {props.dispatch({type: 'SET_CURSOR_ACTIVE', active: false})}}
    	>
    	{props.children}
    </span>
  );
}

export default connect()(LinkWrapper);
