import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import {ReactComponent as IconPrev} from './../../../../assets/icons/icon_arrow_prev.svg'

function SingleNavigation(props) {
  return (
    <div className={`PageModule SingleNavigation`}>
        <div className="col col12">
        	<div className="inner">
            <Link to={props.pages.prev} className="prev link magnetic"><IconPrev/>Prev</Link>
            <Link to={props.pages.next} className="next link magnetic">Next<IconPrev/></Link>
        	</div>
        </div>
    </div>
  );
}

export default connect()(SingleNavigation);
