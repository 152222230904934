import React, {useState, useEffect} from 'react';
import './2_SectionPeopleAtYourService.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {connect} from 'react-redux'

import {ReactComponent as Circle} from './../../../../../assets/operations/circle.svg'
import {ReactComponent as Wheel} from './../../../../../assets/operations/wheel.svg'
import {ReactComponent as Arrow1} from './../../../../../assets/operations/arrow.svg'

function SectionPeopleAtYourService(props) {

useEffect(() => {

  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
        // mobile
          trigger: '#SectionPeopleAtYourService',
          start: '-25% center',
          end: 'bottom center',
          scrub: true,
          // markers: true,
          id: "service"
      },
      desktop: {
        start: `${props.start} 0%`,
        end: "+="+props.length,
        // scrub: props.scrub,
        scrub: props.isMobileSize ? 'true' : props.scrub,
        onEnter: () => props.dispatch({
          type: 'SET_ACTIVE_OPERATIONS_SECTION',
          id: 0
        })
      }
    }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })

  // background color
    if (!props.isMobileSize) tl.to('#animation-container', {backgroundColor: '#fff', ease: 'none', duration: 1},0)

  // opacity
    tl.to('#SectionPeopleAtYourService', {opacity: 1, ease: 'none', duration: 0.5}, 0.5)
    .to('#SectionPeopleAtYourService', {opacity: 0, ease: 'none', duration: 0.5},5.5)

  // background color - leave
    // if (!props.isMobileSize)  
      tl.to('#animation-container', {backgroundColor: '#3B76B9', ease: 'none', duration: 0.5},5.5)
  

  // position
    if (props.isMobileSize) {
      // tl.fromTo('#SectionPeopleAtYourService .text', {y: 0}, {y: 200, ease: 'none', duration: 5.5},0.5)
      // tl.fromTo('#SectionPeopleAtYourService .graphics', {y: 0}, {y: 300, ease: 'none', duration: 5.5},0.5)
      tl.fromTo('#SectionPeopleAtYourService .text', {y: 40}, {y: -40, ease: 'none', duration: 5.5},0.5)
    } else {
      tl.fromTo('#SectionPeopleAtYourService .text', {y: 80}, {y: -80, ease: 'none', duration: 5.5},0.5)
    }


    // .fromTo('#SectionPeopleAtYourService .graphics', {y: 20}, {y: -20, ease: 'none', duration: 5.5},0.5)

  // yellow svg
    tl.fromTo('#SectionPeopleAtYourService .YellowCircleSVG', {x: '60%', y: '60%'}, {x: '60%', y: '60%', rotate: 180, ease: 'none', duration: 6}, 0)

  // blue svg
    .fromTo('#SectionPeopleAtYourService .BlueWheelSVG', {x: '-60%', y: '60%'}, {x: '-60%', y: '60%', rotate: 360, ease: 'none', duration: 6}, 0)

  // first arrow svg
    .fromTo('#SectionPeopleAtYourService .PinkArrowSVG.first', {y: '-60%', x: '-125%'}, {y: '-60%', x: '0%', ease: 'none', duration: 6}, 0)

  // second arrow svg
    .fromTo('#SectionPeopleAtYourService .PinkArrowSVG.second', {y: '-60%', x: '0%'}, {y: '-60%', x: '125%', ease: 'none', duration: 6}, 0)

  // transition out - ALT
    // .to('#SectionPeopleAtYourService .BlueWheelSVG', {scale: 12, x: '200%', y: '-32%', ease: 'none', duration: 2}, 4)
    // .to('#SectionPeopleAtYourService .YellowCircleSVG, #SectionPeopleAtYourService .PinkArrowSVG, #SectionPeopleAtYourService .col.text', {opacity: 0, ease: 'none', duration: 1}, 4)

}, [])

  return ( 
      <section id="SectionPeopleAtYourService" className="mechanics">
          <div className="col graphics">
            <div className="inner">
              <Circle className="YellowCircleSVG mechanicsSVG"/>
              <Arrow1 className="PinkArrowSVG first mechanicsSVG"/>
              <Arrow1 className="PinkArrowSVG second mechanicsSVG"/>
              <Wheel className="BlueWheelSVG mechanicsSVG"/>
            </div>
          </div>
          <div className="col text">
            <div className="inner">
              <h2 className="title">{props.data.headline}</h2>
              <div className="content" dangerouslySetInnerHTML={{__html: props.data.text}}/>
            </div>
          </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(SectionPeopleAtYourService);
