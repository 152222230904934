import React, {useState, useEffect} from 'react';
import './5_NoMatterTheChallenge.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {connect} from 'react-redux'

function NoMatterTheChallenge(props) {
  const [titleWidth, setTitleWidth] = useState(10000)

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

    let scrollOptions = {
    mobile: {
        // mobile
          trigger: '#NoMatterTheChallenge',
          start: 'top center',
          end: 'bottom center',
          // markers: true,
          // pin: '#NoMatterTheChallenge .scrollwrapper',
          scrub: true,
      },
      desktop: {
        start: `${props.start} 0%`,
        end: "+="+props.length,
        // scrub: props.scrub,
        scrub: props.isMobileSize ? 'true' : props.scrub,
        onEnter: () => props.dispatch({
          type: 'SET_ACTIVE_OPERATIONS_SECTION',
          id: 1
        })
      }
    }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })

  // opacity
    .to('#NoMatterTheChallenge', {opacity: 1, ease: 'none', duration: 0.5}, 0)
    .to('#NoMatterTheChallenge', {opacity: 0, ease: 'none', duration: 0.5}, 5.5)

  // background color - leave
    tl.to('#animation-container', {backgroundColor: '#fff', ease: 'none', duration: 0.5}, 5.5)

  // parallax
    // if (props.isMobileSize) tl.to('#NoMatterTheChallenge .scrollwrapper', {y: '100%', ease: 'none', duration: 6},0)

  // textlines
    tl.to('#NoMatterTheChallenge h1', {x: '-100%', ease: 'none', duration: 6}, 0)

  // titlewidth
    var titleEle = document.getElementsByClassName('nomatterthechallenge_title')[0]
    let width = window.innerWidth + titleEle.offsetWidth;
    setTitleWidth(width);

}, [])

  return ( 
      <section id="NoMatterTheChallenge">
        <div className="scrollwrapper">
          <h1 style={{width: titleWidth}}><span className="nomatterthechallenge_title">{props.title}</span></h1>
        </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(NoMatterTheChallenge);
