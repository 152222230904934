const initialState = {
	initialPageHasLoaded: false,
	pageIsLoading: false,
	pagedataCanBeSet: true,
	pageCanBeRevealed: false,
	loaderIsShowing: false,
	loaderIsLeaving: false,
	pageData: null,
	pagesToShow: [],
	scrollhistory: [],
	currentRouterKey: null,
	scrollPosition: 0,
	scrollDir: 'down',
	scrollSpeed: 0,
	scrollDest: null,
	scrollIsAnimating: false,
	modalIsOpen: false,
	mousePos: {x: window.innerWidth/2, y: window.innerHeight/2},
	pageHeight: 0
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'PAGE_IS_LOADING':
			return {
				...state,
				pageIsLoading: true,
				pagedataCanBeSet: false,
			}
		break;

		case 'PAGE_HAS_LOADED':
			const currentPageKey = action.pageObj.url
			return {
				...state,
				initialPageHasLoaded: true,
				pageIsLoading: false,
				pagesToShow: [{key: currentPageKey, component: action.pageObj.component}],
				pageData: {
					...state.pageData,
					[currentPageKey]: {
						component: action.pageObj.component,
						menuName: action.pageObj.menuname,
						data: action.pageObj.data
					}
				}
			}
		break;

		case 'PAGEDATA_CAN_BE_SET':
			return {
				...state,
				pagedataCanBeSet: action.val,
				pageCanBeRevealed: false
			}
		break;

		case 'REVEAL_PAGE':
			return {
				...state,
				pageCanBeRevealed: true
			}
		break;

		case 'LOADER_IS_SHOWING':
			return {
				...state,
				loaderIsShowing: action.val
			}
		break;

		case 'LOADER_IS_LEAVING':
			return {
				...state,
				loaderIsLeaving: action.val
			}
		break;

		case 'SET_PAGEHEIGHT':
			return {
				...state,
				pageHeight: action.height
			}
		break;

		case 'REPLACE_ARCHIVE_POSTS':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							moduledata: {
								...state.pageData[action.pageKey].data.moduledata,
								['archive'+action.cpt]: {
									...state.pageData[action.pageKey].data.moduledata['archive'+action.cpt],
									postsData: action.data
								}
							}
						}
					}
				}
			}
		break;

		case 'UPDATE_ARCHIVE_POSTS':
			var allPostsLoaded = state.pageData[action.pageKey].data.moduledata['archive'+action.cpt].postsData.posts.length + action.newPosts.length == state.pageData[action.pageKey].data.moduledata['archive'+action.cpt].postsData.totalNumOfPosts ? true : false;
			
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							moduledata: {
								...state.pageData[action.pageKey].data.moduledata,
								['archive'+action.cpt]: {
									...state.pageData[action.pageKey].data.moduledata['archive'+action.cpt],
									postsData: {
										...state.pageData[action.pageKey].data.moduledata['archive'+action.cpt].postsData,
										posts: [...state.pageData[action.pageKey].data.moduledata['archive'+action.cpt].postsData.posts, ...action.newPosts],
										allPostsLoaded: allPostsLoaded
									}
								}
							}
						}
					}
				}
			}
		break;

		case 'UPDATE_EVENTS_POSTS':
			var allPostsLoaded = state.pageData[action.pageKey].data.archiveEvents.posts.length + action.newPosts.length == state.pageData[action.pageKey].data.archiveEvents.totalNumOfPosts ? true : false;

			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							archiveEvents: {
								...state.pageData[action.pageKey].data.archiveEvents,
								posts: [...state.pageData[action.pageKey].data.archiveEvents.posts, ...action.newPosts],
								allPostsLoaded: allPostsLoaded
							}
						}
					}
				}
			}
		break;

		case 'NO_PAGE_FOUND':
			return {
				...state,
				pageIsLoading: false,
				pagesToShow: []
			}
		break;

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition,
				scrollDir: action.scrollPosition > state.scrollPosition ? 'down' : 'up'
			}
		break;

		case 'SET_SCROLL_SPEED':
			return {
				...state,
				scrollSpeed: action.val
			}
		break;

		case 'SET_CURRENT_SCROLL':
			const nextRouterKey = action.nextRouterKey ? action.nextRouterKey : 'init';

			return {
				...state,
				currentRouterKey: nextRouterKey,
				scrollhistory: {
					...state.scrollhistory,
					[state.currentRouterKey]: {
						val: window.scrollY
					}
				}
			}
		break;

		case 'UPDATE_ROUTER_KEY':
			return {
				...state,
				currentRouterKey: action.key
			}
		break;

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}
		break;

		case 'SET_MODAL_IS_OPEN':
			return {
				...state,
				modalIsOpen: action.state
			}
		break;

		default:
			return state
	}
}

export default App