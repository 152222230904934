import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'

import {convertHTMLStringToReact} from './../../../helpers'


function SingleCasesArticle(props) {
  return (
    <div className={`PageModule SingleCasesArticle`}>
        <div className="col col10 img">
          <div className="parallaxcontainer" data-parallaxamount={props.isMobile ? 20 : 50} data-parallaxspeed={1} data-imagescale={1.2}>
            <div className="inner">
              <LazyImageComponent height={props.image.sizes['fullscreen-height']} width={props.image.sizes['fullscreen-width']} url={props.image.sizes.fullscreen} imgClassName="parallax-img"/>
            </div>
          </div>
        </div>
        <div className="col col5 article">
          <div className="article-content">{props.article ? convertHTMLStringToReact(props.article) : null}</div>
        </div>
    </div>
  );
}

export default connect(state => ({
  isMobile: state.Device.isMobile
}))(SingleCasesArticle);
