import {store} from './../config/configureStore.js'

export const setDeviceToMobile = (isMobile) => {
    if (isMobile) document.body.classList.add('mobile');
    else document.body.classList.remove('mobile');
    
    store.dispatch({
        type: 'SET_DEVICE_TO_MOBILE',
        isMobile: isMobile
    })
}

export const setDeviceSize = (width, height) => ({
    type: 'SET_DEVICE_SIZE',
    width: width,
    height: height
})

export const setPageHeight = () => {
        setTimeout(() => {
            var ele = document.getElementsByClassName('page')
            store.dispatch({
              type: 'SET_PAGEHEIGHT',
              height: ele.length > 0 ? ele[0].offsetHeight : 0
            })
        }, 50)
    }