const initialState = {
	relativeProgress: [0,0,0],
	activeMenuItem: null
}

const Operations = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_RELATIVE_OPERATIONS_SCROLL_PROGRESS':
			return {
				...state,
				relativeProgress: action.val
			}

		case 'SET_ACTIVE_OPERATIONS_SECTION':
			return {
				...state,
				activeMenuItem: action.id
			}

		default:
			return state
	}
}

export default Operations