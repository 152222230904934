import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import LazyImageComponent from './../../LazyImageComponent'

function NormalpageFullscreenImage(props) {
  return (
  	props.data ? 
    <div className={`PageModule NormalpageFullscreenImage mod${props.num}`}>
        <div className="col col12">
        	<div className="inner">
              <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.url}/>  	
          </div>
    	</div>
    </div>
    : null
  );
}

export default connect()(NormalpageFullscreenImage);
