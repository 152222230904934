import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ColorBoxWrapper from './../ColorBoxWrapper'

function SingleCasesDatabox(props) {
  return (
    <div className={`PageModule SingleCasesDatabox`}>
      <ColorBoxWrapper color={props.color}>
        <div className="col col4 title">
          {props.logo ? <img className="logo" src={props.logo.url}/>
            : props.title}
        </div>
        <div className="col col6 data">
          {props.data ? props.data.map((ele, i) => (
            <div className="entry" key={i}>
              <div className="name">{ele.name}</div>
              <div className="content">{ele.content}</div>
            </div>
          )) : null}
        </div>
      </ColorBoxWrapper>
    </div>
  );
}

export default connect()(SingleCasesDatabox);
