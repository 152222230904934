import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ScrollDownArrow from './../../ScrollDownArrow'

function SingleCasesHeader(props) {
  return (
    <div className={`PageModule SingleCasesHeader`}>
        <div className="col col8 maincol">
        	<div className="inner">
              <h1 className="title">{props.title}</h1>
            	<h4 className="description">{props.description}</h4>
            </div>
    	  </div>
        <ScrollDownArrow/>
    </div>
  );
}

export default connect()(SingleCasesHeader);
