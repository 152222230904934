import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'
import Btn from './../../Btn'

import {wrapString, MathUtils} from './../../../helpers'

import ScrollDownArrow from './../../ScrollDownArrow'


function SinglePeopleHeader(props) {
  const headerImageOffset = useRef();
  const [imageOffsetTop, setImageOffsetTop] = useState(0)
  const [maxScroll, setMaxScroll] = useState(0); 
  const [minScroll, setMinScroll] = useState(0); 
  const [distanceToScroll, setDistanceToScroll] = useState(0); 

  const imageColRef = useRef();
  const stickyRef = useRef();
  const imagePosCurrent = useRef()
  const imagePosPrevious = useRef()
  const requestRef = useRef()
  const parentRef = useRef();

  const [easing, setEasing] = useState('0.1')

/*
  const getImageOffsetTop = () => {
    // let imageWidth = (document.body.clientWidth-20)*0.33333333-20;
    let imageWidthInProcentage = props.windowSize.height <= 735 ? 0.25 : 0.33
    let imageWidth = (document.body.clientWidth-20)*imageWidthInProcentage-20;
    let rel = props.image.height/props.image.width;
    let offsetTop = window.innerHeight/2-imageWidth*rel/2;
    console.log('offsetTop', offsetTop);
    return offsetTop;
  }

  useEffect(() => {
    if (!props.isMobile) {
      setTimeout(() => {
        // let imageWidth = (document.body.clientWidth-20)*0.33333333-20;
        // let rel = props.image.height/props.image.width;
        // let offsetTop = window.innerHeight/2-imageWidth*rel/2;
          let min = parentRef.current.offsetTop
          let distToScroll = imageColRef.current.offsetHeight - getImageOffsetTop() - stickyRef.current.offsetHeight;
          let max = min + distToScroll;
          console.log('distanceToScroll', distToScroll);

          setMinScroll(min)
          setMaxScroll(max)
          setDistanceToScroll(distToScroll)

          headerImageOffset.current = getImageOffsetTop();
          setImagePosFromTop();
      }, 50)
    }
  }, [props.windowSize, props.image])

  const setImagePosFromTop = () => {
    let valToUse = 
      props.scrollPosition >= maxScroll ? distanceToScroll : 
      props.scrollPosition <= minScroll ? 0 :
      props.scrollPosition - minScroll
    imagePosCurrent.current = valToUse + headerImageOffset.current;
  }

  const animateImage = () => {
    imagePosPrevious.current = MathUtils.lerp(imagePosPrevious.current, imagePosCurrent.current, easing)
    if (stickyRef.current) stickyRef.current.style.transform = `translate3d(0,${imagePosPrevious.current}px,0)`;
    // if (ele.length > 0) ele[0].style.transform = `translate3d(0,${imagePosPrevious.current}px,0)`;
    requestRef.current = requestAnimationFrame(animateImage)
  }

  useEffect(() => {
    if (!props.isMobile) setImagePosFromTop();
  }, [props.scrollPosition])

  useEffect(() => {
    if (!props.isMobile) {
      setTimeout(() => {
        setImagePosFromTop();
        imagePosPrevious.current = headerImageOffset.current;
        requestRef.current = requestAnimationFrame(animateImage);
        return () => cancelAnimationFrame(requestRef.current);
      }, 50)
    }
  }, [])

  useEffect(() => {
    imagePosPrevious.current = headerImageOffset.current;
    imagePosCurrent.current = headerImageOffset.current;
  }, [props.pathname])

*/

  return (
    <div className={`PageModule SinglePeopleHeader ${props.pageCanBeRevealed ? 'reveal' : ''}`} ref={parentRef}>
        <div className="col col4 image" ref={imageColRef}>
          <h1 className="name" data-title={props.name}>{props.name}</h1>
          <div className="title">{props.title}</div>
          <div className="inner sticky" /*style={{top: imageOffsetTop}}*/ ref={stickyRef}>
            <LazyImageComponent height={props.image.sizes['large-height']} width={props.image.sizes['large-width']} url={props.image.sizes['large']}/>
          </div>
        </div>
        <div className="col col5 text">
        	<div className="inner section">
            <h1 className="name" data-title={props.name}>{props.name}</h1>
            <div className="title">{props.title}</div>
          	{props.description ? <p className="description">{props.description}</p> : null}
            {props.some.email || props.some.linkedin ? 
              <div className="some">
                {props.some.email ? <Btn url={props.some.email} label="Email" type="email"/> : null}
                {props.some.linkedin ? <Btn url={props.some.linkedin} label={props.some.linkedin_label} type="external"/> : null}
              </div>
            : null}
          </div>
          <div className="inner section maintext" dangerouslySetInnerHTML={{__html: props.maincontent}}/>
    	  </div>
        {props.maincontent.length > 0 ? <ScrollDownArrow/> : null}
    </div>
  );
}

export default connect((state) => ({
  windowSize: state.Device.size,
  pageCanBeRevealed: state.App.pageCanBeRevealed,
  scrollPosition: state.App.scrollPosition,
  pathname: state.router.location.pathname,
  isMobile: state.Device.isMobile
}))(SinglePeopleHeader);