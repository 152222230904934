import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'

function NormalpageTwoColumn(props) {
  return (
  	props.data ? 
    <div className={`PageModule NormalpageTwoColumn mod${props.num}`}>
      <div className={`col ${props.data.left_column_type == 'image' ? `col4 image` : `col3 text`}`}>
        <div className="inner">
          {props.data.left_column_type == 'image' ?
           <LazyImageComponent height={props.data.left_column_image.height} width={props.data.left_column_image.width} url={props.data.left_column_image.url}/> :
           <h2>{props.data.left_column_text}</h2>
          }
        </div>
      </div>
      <div className={`col ${props.data.right_column_type == 'image' ? `col5 image` : `col4 text`}`}>
        <div className="inner">
          {props.data.right_column_type == 'image' ?
           <LazyImageComponent height={props.data.right_column_image.height} width={props.data.right_column_image.width} url={props.data.right_column_image.url}/> :
           <div dangerouslySetInnerHTML={{__html: props.data.right_column_text}}/>
          }
        </div>
      </div>
    </div>
    : null
  );
}

export default connect()(NormalpageTwoColumn);
