import React, {useState, useEffect} from 'react';
import './index.scss';

import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as MenuIconBurger} from './../../../assets/icons/icon_menu_burger.svg'
import {ReactComponent as MenuIconX} from './../../../assets/icons/icon_menu_x.svg'

function Mainmenu(props) {
	const [mobileSubmenuIdOpenOnMobile, setMobileSubmenuIdOpenOnMobile] = useState(null)
	const [currentColor, setCurrentColor] = useState(0);

	const openMenu = () => {
		props.dispatch({type: 'OPEN_MAINMENU'})
	}

	const closeMenu = () => {
		props.dispatch({type: 'CLOSE_MAINMENU'})
	}

  return (
  		props.initialPageHasLoaded ? 
	  		<div className={`Mainmenu${props.mainmenuIsOpen ? ' open' : ''} ${props.resetMainmenu ? 'reset' : ''}`}>
		  		<MenuIconBurger onClick={openMenu} className={`icon-menu-toggle show-menu ${props.scrollDir == 'down' && props.scrollPosition > 200 ? 'hide' : 'show'}`}/>
		  		<MenuIconX onClick={closeMenu} className="icon-menu-toggle close-menu"/>
		    	{props.menu && props.menu.mainmenu.main ?
		    		<div>
		    			<div className={`PageModule menucontainer`}>
		    				<div className="inner">
			    				{props.menu.mainmenu.main.map((ele, i) => (
				    				<div className="menu-item" key={i}><NavLink onClick={closeMenu} to={{pathname: `/` + ele.post_name, state: {fromTag: true}}} data-title={ele.post_title} data-magneticscrolllisten={false}><span>{ele.post_title}</span></NavLink></div>
		    					))}
		    				</div>
		    			</div>
	    			</div>
	    		: null}
	    	</div>
    	: null
  );
}

export default connect((state) => ({
	menu: state.Globals.menu,
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	resetMainmenu: state.Globals.resetMainMenu,
	initialPageHasLoaded: state.App.initialPageHasLoaded,
	scrollDir: state.App.scrollDir,
	scrollPosition: state.App.scrollPosition
}))(Mainmenu)