import React, {useState, useEffect} from 'react';
import './1_SectionHero.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {connect} from 'react-redux'

function SectionHero(props) {

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
      // mobile
        trigger: '#SectionHero',
        start: 'top top',
        end: 'bottom center',
        scrub: true,
        // markers: true,
        id: "intro"

      // desktop
        // start: `${props.start} 0%`,
        // end: "+="+props.length,
        // scrub: props.scrub,
    },
    desktop: {
      // mobile
        // trigger: '#SectionHero',
        // start: 'top top',
        // end: 'bottom center',

      // desktop
        start: `${props.start} 0%`,
        end: "+="+props.length,
        scrub: props.isMobileSize ? 'true' : props.scrub,
  }
}

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })


  // if (!props.isMobileSize) 
    tl.to('#SectionHero', {y: -200, ease: 'none', duration: 1})
  // else tl.to('#SectionHero', {y: 300, ease: 'none', duration: 1})
  tl.to('#SectionHero', {opacity: 0, ease: 'none', duration: 0.5}, 0.5)

  // set backgroundColor on load
  // var ele = document.getElementById('animation-container');
  // ele.style.backgroundColor = '#F4A2D4';
  // tl.set('#animation-container', {backgroundColor: '#F4A2D4'}, 0)

}, [])

  return ( 
      <section id="SectionHero">
        <h1 className="hero-headline1">{props.data.line1}</h1>
      	<h1 className="hero-headline2">{props.data.line2}</h1>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(SectionHero);
