import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {ReactComponent as ScrollIcon} from './../../../assets/icons/icon_arrow_scroll.svg'

function ScrollDownArrow(props) {
  return !props.isMobile ? <ScrollIcon className={`scrollicon ${props.scrollPosition > 0 ? 'hide' : 'show'}`}/> : null
}

export default connect((state) => ({
	scrollPosition: state.App.scrollPosition,
	isMobile: state.Device.isMobile
}))(ScrollDownArrow)
