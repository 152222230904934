import React from 'react'
import './LoaderLogo.scss';

import {ReactComponent as IconLogo} from './../../../assets/icons/icon-logo-alt.svg'
import {connect} from 'react-redux'

function LoaderLogo(props) {
	return (
		<div id="LoaderLogo" className={`${props.loaderIsLeaving ? 'leaving' : ''} ${props.loaderIsShowing && !props.loaderIsLeaving? 'showing' : ''} ${!props.initialPageHasLoaded ? 'init' : ''}`}>
			<IconLogo/>
		</div>
		)
}

export default connect(state => ({
	loaderIsShowing: state.App.loaderIsShowing,
	loaderIsLeaving: state.App.loaderIsLeaving,
	initialPageHasLoaded: state.App.initialPageHasLoaded
}))(LoaderLogo)