import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'
import LazyImageComponent from './../../LazyImageComponent'

function SingleContentMain(props) {
    return <div className={`PageModule SingleContentMain`}>;
    {props.data?.map((ele, i) => {
      if (ele.layout === 'two-col') {
       return(
        <div className="section" key={i}>
            <div className="heading">
                {ele.left_column}
            </div>
            <div className="flex">
                <div className="col col4">
                <div className="inner" dangerouslySetInnerHTML={{__html: ele.left_column_text}}/>
                </div>
                <div className="col col4">
                <div className="inner" dangerouslySetInnerHTML={{__html: ele.right_column}}/>
                </div>
            </div>
        </div>
       )
      } else if (ele.layout === 'full-width') {
        return (
            <div className="section" key={i}>
                <div className="heading">
                    {ele.left_column}
                </div>
                <div className="flex">
                    <div className="col col8">
                        <div className="inner" dangerouslySetInnerHTML={{__html: ele.right_column}}/>
                    </div>
                </div>
            </div>
        )
      } else {
        return (
            <div className="section" key={i}>
                <div className="flex">
                  <div className="col col4 heading">
                    <div className="inner">
                      {ele.left_column}
                    </div>
                  </div>
                  <div className="col col4">
                    <div className="inner" dangerouslySetInnerHTML={{__html: ele.right_column}}/>
                  </div>
                </div>
            </div>
        )
      }
    })}
    </div>;
}

export default connect()(SingleContentMain);
