import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

function SiteLoader(props) {
	/*const [enterLoad, setEnterLoad] = useState(false)
	const [loading, setLoading] = useState(false)
	const [exitLoad, setExitLoad] = useState(false)
	const [exitLoadIsPossible, setExitLoadIsPossible] = useState(false);

	const 	animationSpeed = 400,
			minimalLoadscreenTime = 0;

	useEffect(() => {
		if (props.pageIsLoading) {
			setExitLoadIsPossible(false);
			setEnterLoad(true);
			setLoading(true);


			setTimeout(() => {
				setEnterLoad(false);
				props.dispatch({
					type: 'PAGEDATA_CAN_BE_SET',
					val: true
				})
				console.log('data can be set in store');
			}, animationSpeed)

			setTimeout(() => {
				setExitLoadIsPossible(true)
				console.log('exit load is possible');
			}, animationSpeed+minimalLoadscreenTime)
		}
	},[props.pageIsLoading])

	useEffect(() => {
		if (!props.pageIsLoading && exitLoadIsPossible) {
			console.log('reveal page');
			props.dispatch({
				type: 'REVEAL_PAGE'
			})
			setExitLoad(true);	

			setTimeout(() => {
				setLoading(false);
				setExitLoad(false);	
			}, animationSpeed)
		}
	}, [props.pageIsLoading, exitLoadIsPossible])

  return (
    <div className={`${loading ? 'loading ' : ''}${enterLoad ? 'enter' : ''}${exitLoad ? 'exit' : ''} siteloader`}/>
  );*/
}

export default connect(state => ({
	pageIsLoading: state.App.pageIsLoading
}))(SiteLoader);
