import React, {useState, useEffect} from 'react';
import './6_PushingYourStartup.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import {connect} from 'react-redux'

import {ReactComponent as PushingRed} from './../../../../../assets/operations/pushing_red.svg'
import {ReactComponent as PushingYellow} from './../../../../../assets/operations/pushing_yellow.svg'
import {ReactComponent as PushingBlue} from './../../../../../assets/operations/pushing_blue.svg'
import {ReactComponent as PushingPink} from './../../../../../assets/operations/pushing_pink.svg'

function PushingYourStartup(props) {

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
      // mobile
        trigger: '#PushingYourStartup',
        start: 'top 50%',
        end: 'bottom 50%',
        scrub: true,
    },
    desktop: {
      start: `${props.start} 0%`,
      end: "+="+props.length,
      // scrub: props.scrub,
      scrub: props.isMobileSize ? 'true' : props.scrub,
      onEnter: () => props.dispatch({
        type: 'SET_ACTIVE_OPERATIONS_SECTION',
        id: 2
      }),
      onEnterBack: () => props.dispatch({
        type: 'SET_ACTIVE_OPERATIONS_SECTION',
        id: 2
      })
    }
  }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })
    
  // opacity
    .to('#PushingYourStartup', {opacity: 1, ease: 'none', duration: 0.5}, 0)
    .to('#PushingYourStartup', {opacity: 0, ease: 'none', duration: 0.5}, 4)
  
  // text
    // .fromTo('#PushingYourStartup .graphics', {y: 60}, {y: -60, ease: 'none', duration: 4.5}, 0.5)
    // if (!props.isMobileSize)
     tl.fromTo('#PushingYourStartup .text', {y: 80}, {y: -80, ease: 'none', duration: 4.5}, 0)

  // red
    tl.fromTo('#PushingYourStartup .PushingRed', {rotate: 0, x: '-60%', y: '100%'}, {rotate: -60, x: '-60%', y: '-50%', ease: 'none', duration: 2.5}, 0)
    .to('#PushingYourStartup .PushingRed', {rotate: -180, x: '-60%', y: '-200%', ease: 'none', duration: 1}, 2.5)
    .to('#PushingYourStartup .PushingRed', {opacity: 0, ease: 'none', duration: .5}, 3)

  // yellow
    .fromTo('#PushingYourStartup .PushingYellow', {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.5}, 1.5)
    .fromTo('#PushingYourStartup .PushingYellow', {rotate: 0, x: '-60%', y: '200%'}, {rotate: 90, x: '-60%', y: '50%', ease: 'none', duration: 1}, 1.5)
    .to('#PushingYourStartup .PushingYellow', {rotate: 110, y: '-90%', ease: 'none', duration: 2}, 2.5)

  //blue
    .fromTo('#PushingYourStartup .PushingBlue', {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.5}, 0.75)
    .fromTo('#PushingYourStartup .PushingBlue', {x: '60%', y: '150%'}, {x: '60%', y: '-25%', ease: 'none', duration: 2.50}, 0.75)
    .to('#PushingYourStartup .PushingBlue', {x: '60%', y: '-250%', ease: 'none', duration: 1.25}, 3.25)

  // pink
    .fromTo('#PushingYourStartup .PushingPink', {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.25}, 2.25)
    .fromTo('#PushingYourStartup .PushingPink', {rotate: 0, x: '70%', y: '300%'}, {rotate: 120, x: '70%', y: '96%', ease: 'none', duration: 1}, 2.25)
    .to('#PushingYourStartup .PushingPink', {rotate: 140, y: '-50%', ease: 'none', duration: 1.25}, 3.25)

}, [])

  return ( 
      <section id="PushingYourStartup" className="mechanics">
          <div className="col graphics">
            <div className="inner">
              <PushingRed className="PushingRed mechanicsSVG"/>
              <PushingYellow className="PushingYellow mechanicsSVG"/>
              <PushingBlue className="PushingBlue mechanicsSVG"/>
              <PushingPink className="PushingPink mechanicsSVG"/>
            </div>
          </div>
          <div className="col text">
            <div className="inner">
              <h2 className="title">{props.data.headline}</h2>
              <div className="content" dangerouslySetInnerHTML={{__html: props.data.text}}/>
            </div>
          </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(PushingYourStartup);
