import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ColorBoxWrapper from './../ColorBoxWrapper'
import Btn from './../../Btn'
import {distance} from './../../../helpers'

function LinkModule(props) {
  const [distToMouse, setDistToMouse] = useState(0)

  return (
  	props.data ? 
    <div className={`PageModule LinkModule mod${props.num}`}>
      <ColorBoxWrapper color={props.data.backgroundcolor}>
        <div className="col col12">
        	<div className="inner">
            	{props.data.text ? <h1 className="title">{props.data.text}</h1> : null}
              <Btn url={props.data.link_type == 'internal' ? '/'+props.data.page[0].post_name : props.data.url} label={props.data.label} type={props.data.link_type}/>
            </div>
    	  </div>
      </ColorBoxWrapper>
    </div>
    : null
  );
}

export default connect()(LinkModule);
