import paper from 'paper';
import {store} from './../../config/configureStore'

export default class SiteLoader {
	constructor(pos) {
		// variables
			this.innerPath = '';

			this.easing = 'easeOutQuad';

			this.loaderIsShowing = false;
			this.loaderIsLeaving = false;

			this.offsetY = 100;
			this.offsetX = 100;
			this.size = 200;
			this.resetPoints = [];
			this.initPoints = [];

			this.animationTweens = [];

			// this.colors = ['#FFD100', '#F4A2D4', '#85c8e7', '#2e78bc', '#1F1C37', '#E2DEC9', '#eb5757']
			this.colors = ['#F9DDC9']

			this.initialLoad = true;

		// initPath
			this.initResetPoints();
			this.initPath();

		// init redux
			store.subscribe(() => this.updateOnRedux(this));
	}

	update() {
		this.innerPath.smooth({ type: 'continuous' });
	}

	updateOnResize() {
		this.initResetPoints();
	}

	initResetPoints() {
		this.resetPoints = [
			[-this.offsetX, window.innerHeight+this.offsetY/2],
			[window.innerWidth/2, window.innerHeight+this.offsetY/2],
			[window.innerWidth+this.offsetX, window.innerHeight+this.offsetY/2],
			[window.innerWidth+this.offsetX, window.innerHeight+this.offsetY],
			[window.innerWidth/2, window.innerHeight+this.offsetY],
			[-this.offsetX, window.innerHeight+this.offsetY]
		];

		this.initPoints = [
			[-this.offsetX, -this.offsetY],
			[window.innerWidth/2, -this.offsetY],
			[window.innerWidth+this.offsetX, -this.offsetY],
			[window.innerWidth+this.offsetX, window.innerHeight+this.offsetY],
			[window.innerWidth/2, window.innerHeight+this.offsetY],
			[-this.offsetX, window.innerHeight+this.offsetY]
		]
	}

	initPath() {
		this.innerPath = new paper.Path({
			segments: this.initPoints,
		    fillColor: this.getRandomColor(),
		    closed: true,
		    visible: true,
		    // selected: true
		});
	}

	initializeLoadSequence() {
		this.loaderIsShowing = true;

		if (!this.initialLoad) {
			this.resetPath();
			this.animateIn.option1();
		}
	}

	initializeLeaveSequence() {
		this.initialLoad = false;
		this.loaderIsLeaving = true;

		this.animateOut.option1();
	}

	hideLoadSequence() {
		this.loaderIsShowing = false;
		this.loaderIsLeaving = false;
	}

	showLoaderWithoutAnimation() {
		this.resetPath();

		this.loaderIsShowing = true;
		
		this.innerPath.segments[0].point.y = -this.offsetY;
		this.innerPath.segments[1].point.y = -this.offsetY;
		this.innerPath.segments[2].point.y = -this.offsetY;

		this.innerPath.visible = true;
		this.innerPath.fillColor = this.getRandomColor();

	}

	animateIn = {
		option1: () => {
			this.animatePath.topMiddle_up();

			setTimeout(() => {
				this.animatePath.topSides_up();
			}, 100)
		},
	}

	animateOut = {
		option1: () => {
			this.animatePath.bottomSides_up();

			setTimeout(() => {
				this.animatePath.bottomMiddle_up();
			}, 50)
		},
	}

	animatePath = {
		topMiddle_up: () => {
			this.innerPath.visible = true;

			let tween = this.innerPath.tweenTo({
				'segments[1].point.y': -this.offsetY,
			}, {
				duration: 500,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		topSides_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[0].point.y': -this.offsetY,
				'segments[2].point.y': -this.offsetY
			}, {
				duration: 600,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		bottomSides_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[3].point.y': -this.offsetY/2,
				'segments[5].point.y': -this.offsetY/2
			}, {
				duration: 400,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		},

		bottomMiddle_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[4].point.y': -this.offsetY/2,
			}, {
				duration: 500,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		}
	}

	resetPath () {
		for (let i = 0 ; i < this.animationTweens.length; i++) {
			this.animationTweens[i].stop();
		}
		
		this.innerPath.visible = false;
		this.innerPath.fillColor = this.getRandomColor();

		for (let i = 0; i < this.resetPoints.length; i++) {
			this.innerPath.segments[i].point.x = this.resetPoints[i][0];
			this.innerPath.segments[i].point.y = this.resetPoints[i][1];
		}
	}

	getRandomColor() {
		return this.colors[Math.floor(Math.random()*this.colors.length)]
	}

	updateOnRedux(_this) {
		let loaderIsShowingStore = store.getState().App.loaderIsShowing;
		let loaderIsLeavingStore = store.getState().App.loaderIsLeaving;

		if (loaderIsShowingStore) {
			if (!_this.loaderIsShowing) _this.initializeLoadSequence();
			if (loaderIsLeavingStore) {
				if (!_this.loaderIsLeaving) _this.initializeLeaveSequence();
			}
		} else {
			if (_this.loaderIsShowing) _this.hideLoadSequence();
		}
	}
}