import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link, useLocation} from 'react-router-dom'
import ArchiveContentThumb from './../ArchiveContentThumb'
import ArchiveFilter from './../ArchiveFilter'
import {getApiBase} from './../../../helpers'

function ArchiveContent(props) {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentCat, setCurrentCat] = useState(null);
  const initialLoad = useRef(true);
  let location = useLocation();

    const fetchData = async (query) => {
        setIsFetchingData(true);
        let queryToGet = '?cpt=stuff';
        queryToGet += query.cat ? '&cat='+query.cat+'&tax=stuff_category' : '';
        queryToGet += '&posts_per_page='+props.moduleData.postsData.postsPerPage;
        queryToGet += query.page ? '&page='+query.page : '';

        const url = getApiBase()+'/wp-json/data/v2/getarchivestuff'+queryToGet;
        const response = await fetch(url);
        const jsonResponse = await response.json();

        if (query.page) {
          // add to archivedata in redux
	          props.dispatch({
	              type: 'UPDATE_ARCHIVE_POSTS',
	              cpt: 'stuff',
	              newPosts: jsonResponse.posts,
	              pageKey: props.slug
	          })
        } else {
          // replace all archivedata in redux
            props.dispatch({
              type: 'REPLACE_ARCHIVE_POSTS',
              cpt: 'stuff',
              data: jsonResponse,
              pageKey: props.slug
            })

          // scroll to top
            // const ele = document.getElementsByClassName('ArchiveModule')[0];
            // let offsetTop = {desktop: 100, mobile: 70}
            // const scrollDest = ele.getBoundingClientRect().y  + window.pageYOffset - (shouldBreak ? offsetTop.mobile : offsetTop.desktop);
            // window.scrollTo(0,scrollDest);
        }

        setIsFetchingData(false);

        props.dispatch({
          type: 'UPDATE_ROUTER_KEY',
          key: props.currentRouterKey
        })
  }

  useEffect(() => {
    if (props.slug == props.currentRouterPathname) {
      if (initialLoad.current) initialLoad.current = false;
      else {
        if (props.query) fetchData(props.query);
      }

      setCurrentCat(props.query.cat);
    }
  }, [props.query])

  return (
  	props.moduleData ? 
	    <div className={`PageModule ArchiveContent mod${props.num}`}>
        <ArchiveFilter width="12" title="Filter by category" filterElements={props.moduleData.filter.stuff_category} currentCat={currentCat}/>
	    	<div className="results">
	        	{props.moduleData.postsData.posts.map((ele, i) => {
	            return <ArchiveContentThumb key={ele.post_name} post_name={ele.post_name} image_thumb={ele.image_thumb} post_title={ele.post_title} tags={ele.tags} width="3"/>
	          })}
	    	</div>
	    	{
    	 		props.moduleData.postsData.posts ? !props.moduleData.postsData.allPostsLoaded ? 
    	 			<Link className="btn loadmore_btn" to={{search: `?page=${props.currentRouterPage ? parseInt(props.currentRouterPage)+1 : '2'}${props.currentRouterCat ? '&cat='+props.currentRouterCat : ''}`, state: {fromTag: true}}}>Load more</Link>
	 			: null : null
	 		}
	    </div>
    : null
  );
}

export default connect((state) => ({
    query: state.router.location.query,
    currentRouterPathname: state.router.location.pathname,
    currentRouterKey: state.router.location.key,
    currentRouterPage: state.router.location.query ? state.router.location.query.page ? state.router.location.query.page : null : null,
    currentRouterCat: state.router.location.query ? state.router.location.query.cat ? state.router.location.query.cat : null : null
}))(ArchiveContent);
