import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link, useLocation} from 'react-router-dom'
import ArchivePeopleThumb from './../ArchivePeopleThumb'
import ArchiveFilter from './../ArchiveFilter'
import {getApiBase} from './../../../helpers'

import {setPageHeight} from './../../../actions'

function ArchivePeople(props) {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentCat, setCurrentCat] = useState(null);
  const [peopleToShow, setPeopleToShow] = useState([]);
  const initialLoad = useRef(true);
  let location = useLocation();

  const fetchData = async (query) => {
        setIsFetchingData(true);
        let queryToGet = '?cpt=stuff';
        queryToGet += query.cat ? '&cat='+query.cat+'&tax=people_category' : '';

        const url = getApiBase()+'/wp-json/data/v2/getarchivepeople'+queryToGet;
        const response = await fetch(url);
        const jsonResponse = await response.json();

        // replace all archivedata in redux
          props.dispatch({
            type: 'REPLACE_ARCHIVE_POSTS',
            cpt: 'people',
            data: jsonResponse,
            pageKey: props.slug
          })

        setTimeout(() => {
          setIsFetchingData(false);
          setPageHeight();
        }, 500);

        props.dispatch({
          type: 'UPDATE_ROUTER_KEY',
          key: props.currentRouterKey
        })
  }

  useEffect(() => {
    if (props.slug == props.currentRouterPathname) {
      if (initialLoad.current) initialLoad.current = false;
      else {
        if (props.query) fetchData(props.query);
      }

      let peopleToShow = [];
      if(props.data.select_people) {
          props.data.select_people.forEach((ele) => {
              let eleToPush = props.moduleData.postsData.posts.find((i) => i.post_name == ele.post_name)
              peopleToShow.push(eleToPush);
          });
      }

      setPeopleToShow(peopleToShow);
      setCurrentCat(props.query.cat);
    }
  }, [props.query])

  return (
  	props.data ?
    <div className={`PageModule ArchivePeople mod${props.num}`}>
        <div className="col col10 results">
        	<div className={`inner archivepeopleinner ${isFetchingData ? 'loading' : ''}`}>
                {peopleToShow.map((ele, i) => {
                    return <ArchivePeopleThumb data={ele} key={i} debug={i == 0 ? true : false}/>
                  })}
          </div>
    	</div>
    </div>
    : null
  );
}

export default connect((state) => ({
    query: state.router.location.query,
    currentRouterPathname: state.router.location.pathname,
    currentRouterKey: state.router.location.key,
}))(ArchivePeople);
