import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'

import SEO from './../SEO'
import Footer from './../Footer'

import SingleContentHeader from './../PageModules/SingleContentHeader'
import SingleContentMain from './../PageModules/SingleContentMain'
import SingleContentRelated from './../PageModules/SingleContentRelated'
import SingleNavigation from './../PageModules/SingleNavigation'

function SingleContent(props) {

  useEffect(() => {
    // if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    // else window.scrollTo(0,0);
  }, [props.dataKey])

  return (
  	<div className={`singlecontent page${props.mainmenuIsOpen ? ' open' : ''}`} key={props.apiData.post_title}>
      <SEO dataKey={props.dataKey} title={props.apiData.post_title} description={props.apiData.meta_description}/>
      <SingleContentHeader title={props.apiData.post_title} author={props.apiData.author ? props.apiData.author[0] : null} tags={props.apiData.tags} heroImage={props.apiData.hero_image} heroVideo={props.apiData.hero_video} contentType={props.apiData.hero_media_choice}/>
      <SingleContentMain data={props.apiData.sections || []}/>
      <SingleNavigation pages={props.apiData.next_prev}/>
      <SingleContentRelated related={props.apiData.related}/>

      <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
  return ({
	apiData: state.App.pageData[ownProps.dataKey].data,
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
  routerAction: state.router.action,
  meta: state.Globals.meta,
})
})(SingleContent);
