import ReactGA from 'react-ga';
import {history} from './configureStore'

export const configureAnalytics = () => {
	ReactGA.initialize('UA-196816142-1', {
		debug: false
	});
	history.listen(location => {
		ReactGA.set({page: location.pathname});
		ReactGA.pageview(location.pathname);
	})
}