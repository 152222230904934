import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux'
import {ReactComponent as IconLogoHero} from './../../../../assets/icons/icon-logo-hero.svg'
import FrontpageSliderImageSlide from "../FrontpageSliderImageSlide";
import FrontpageSliderVideoSlide from "../FrontpageSliderVideoSlide";
import './index.scss';

function FrontpageSlider(props) {
	const [activeIndex, setActiveIndex] = useState(0);
	const [sliderInit, setSliderInit] = useState(false);
	const slides = props.data?.slides;
	// Image slide interval from acf (default 1500ms)
	// Video slide interval from video length (default 4500ms)
	const slideInterval = slides[activeIndex].slide_layout === 'image' ? 1500 : 4500;

	for (const slide of slides) {
		if(slides[activeIndex].slide_layout === 'image'){
			const imageElement = new Image();
			imageElement.src = slide.people?.url;
		}
	}

	setTimeout(() => {
		setSliderInit(true);
	}, 1500);

	function changeSlide(){
		if(sliderInit == true){
			let index = activeIndex;
			if(activeIndex < slides.length - 1){
				index++;
			} else {
				index = 0;
			}
			setActiveIndex(() => index);
			//console.log("CHANGE SLIDE", slides[activeIndex]);
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			changeSlide();
		}, slideInterval);

		return () => clearInterval(interval);
	});

	return <div className={`PageModule FrontpageSlider mod${props.num}`}>
		{props.data?.slides?.map((ele, i) => {
			return(
				<div className={`slide ${ele.background_color} ${i == activeIndex ? 'active' : ''}`} key={i}>
					<div className={`LogoWrap ${ele.logo_color}`}>
						<IconLogoHero className={`SlideLogo ${sliderInit ? 'loaded' : ''}`}/>
					</div>
					{ele.slide_layout === 'image' ? <FrontpageSliderImageSlide data={ele} init={sliderInit}/> : <FrontpageSliderVideoSlide data={ele} init={sliderInit}/>}
				 </div>
			)

		})}
	</div>;
  // return (
  // 	props.data ?
  //   <div className={`PageModule FrontpageSlider mod${props.num} ${props.videoIsLoading ? 'loading' : ''}`}>
  //   	{/*videoIsLoading ? <img src="/video_poster.jpg" className="poster"/> : null*/}
  //   	<video muted loop playsInline autoPlay ref={videoPlayer} onCanPlay={() => videoCanPlay()} >
  //   		<source src={props.isHorizontal ? props.data.video : props.data.video_mobile} type="video/mp4"/>
  //   	</video>
  //   </div>
  //   : null
  // );
}

export default connect(state => ({
	isHorizontal: state.Device.size.width > state.Device.size.height
}))(FrontpageSlider);
