import React, {useState, useEffect} from 'react';
import './9_ActionSpeaksLouderThanWords.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as LinkArrow} from './../../../../../assets/operations/linkarrow.svg'


function ActionSpeaksLouderThanWords(props) {
  const [titleWidth, setTitleWidth] = useState(10000)

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)


  // if (!props.isMobileSize) {
    let scrollOptions = {
      mobile: {
        // mobile
          trigger: '#ActionSpeaksLouderThanWords',
          start: 'top 80%',
          end: 'bottom center',
          scrub: true,
      },
      desktop: {
        start: `${props.start} 0%`,
        end: "+="+props.length,
        scrub: props.isMobileSize ? 'true' : props.scrub,
        onEnter: () => props.dispatch({
          type: 'SET_ACTIVE_OPERATIONS_SECTION',
          id: 4
        })
      }
    }


    let tl = gsap.timeline({
      scrollTrigger: scrollOptions.desktop
    })

    // background + fixed menu color
      .to('.StickyMenu .menu-item', {color: '#16182F', ease: 'none', duration: 1},0)
      .to('.StickyMenu .menu-item .activearrow path', {fill: '#16182F', ease: 'none', duration: 1},0)


    // headline
      .to('#ActionSpeaksLouderThanWords h1', {x: '-100%', ease: 'none', duration: 2}, 0)

      // p1
      .fromTo('#ActionSpeaksLouderThanWords h2:nth-of-type(1)', {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.25}, 2)
      .fromTo('#ActionSpeaksLouderThanWords h2:nth-of-type(1)', {y: 80}, {y: -80, ease: 'none', duration: 1}, 2)
      .to('#ActionSpeaksLouderThanWords h2:nth-of-type(1)', {opacity: 0, ease: 'none', duration: .25}, 2.75)

    // p2
      .fromTo('#ActionSpeaksLouderThanWords h2:nth-of-type(2)', {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.25}, 3)
      .fromTo('#ActionSpeaksLouderThanWords h2:nth-of-type(2)', {y: 80}, {y: -80, ease: 'none', duration: 1}, 3)
      .to('#ActionSpeaksLouderThanWords h2:nth-of-type(2)', {opacity: 0, ease: 'none', duration: .25}, 3.75)

    // position
      let dlyLeave = 0;

      for (let i = 1; i < props.items.length+1; i++) {
        let dlyEnter = (i-1)+4;
        let yDest = i !== props.items.length ? -60 : 0

        dlyLeave = (i-1)+4+0.75;
        tl.set(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {css:{'pointer-events': 'auto'}}, dlyEnter)
        tl.fromTo(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {y: '60'}, {y: yDest, ease: 'none', duration: 1}, dlyEnter)
        .fromTo(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {opacity: '0'}, {opacity: '1', ease: 'none', duration: 0.25}, dlyEnter)
        
        if (i !== props.items.length) {
          tl.to(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {opacity: '0', ease: 'none', duration: 0.25}, dlyLeave)
          .set(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {css:{'pointer-events': 'none'}}, dlyLeave+0.25)
        }
      }

    // opacity
      tl.to('#ActionSpeaksLouderThanWords', {opacity: 1, ease: 'none', duration: 0.5}, 0)
      .to('#ActionSpeaksLouderThanWords', {opacity: 0, ease: 'none', duration: 0.5}, dlyLeave-0.3)

    // titlewidth
      var titleEle = document.getElementsByClassName('actionspeaks_title')[0]
      let width = window.innerWidth + titleEle.offsetWidth;
      setTitleWidth(width);
/*
  } else {

    // main title
      let tl_title = gsap.timeline({
        scrollTrigger: {
          trigger: '#ActionSpeaksLouderThanWords h1',
          start: 'top 80%',
          end: 'bottom 60%',
          scrub: true,
        }
      })

      tl_title.to('#ActionSpeaksLouderThanWords h1', {opacity: 1, ease: 'none', duration: .5}, 0)
      tl_title.to('#ActionSpeaksLouderThanWords h1', {opacity: 0, ease: 'none', duration: .5}, 4)

    // subtitle 1
      let tl_subtitle1 = gsap.timeline({
        scrollTrigger: {
          trigger: '#ActionSpeaksLouderThanWords h2.first',
          start: 'top 60%',
          end: 'bottom 60%',
          scrub: true,
        }
      })

      tl_subtitle1.to('#ActionSpeaksLouderThanWords h2.first', {opacity: 1, ease: 'none', duration: .5}, 0)
      tl_subtitle1.to('#ActionSpeaksLouderThanWords h2.first', {opacity: 0, ease: 'none', duration: .5}, 4)
      tl_subtitle1.fromTo('#ActionSpeaksLouderThanWords h2.first', {y: -50}, {y: 50, ease: 'none', duration: 4.5}, 0)

    // subtitle 2
      let tl_subtitle2 = gsap.timeline({
        scrollTrigger: {
          trigger: '#ActionSpeaksLouderThanWords h2.second',
          start: 'top 60%',
          end: 'bottom center',
          scrub: true,
        }
      })

      tl_subtitle2.to('#ActionSpeaksLouderThanWords h2.second', {opacity: 1, ease: 'none', duration: .5}, 0)
      tl_subtitle2.to('#ActionSpeaksLouderThanWords h2.second', {opacity: 0, ease: 'none', duration: .5}, 4)
      tl_subtitle2.fromTo('#ActionSpeaksLouderThanWords h2.second', {y: -50}, {y: 50, ease: 'none', duration: 4.5}, 0)


    // cases slides
    
    const toggleActiveClass = (index, val) => {
      var ele = document.querySelector(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${index})`);
      if (ele) {
        if (val) ele.classList.add('active');
        else if (!val) ele.classList.remove('active');
      }
    } 

    let tl_caselink

    for (let i = 1; i < props.items.length+1;i++) {
      tl_caselink = gsap.timeline({
        scrollTrigger: {
          trigger: `#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`,
          start: 'top center',
          end: 'bottom center',
          scrub: true,
          onEnter: () => toggleActiveClass(i, true),
          onLeave: () => toggleActiveClass(i, false),
          onEnterBack: () => toggleActiveClass(i, true),
          onLeaveBack: () => toggleActiveClass(i, false)
        }
      })
      // tl_caselink.set(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {css:{'pointer-events': 'auto'}}, 0)
      // tl_caselink.set(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {css:{'pointer-events': 'none'}}, 4.5)
      tl_caselink.to(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {opacity: 1, ease: 'none', duration: .5}, 0)
      tl_caselink.to(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {opacity: 0, ease: 'none', duration: .5}, 4)
      tl_caselink.fromTo(`#ActionSpeaksLouderThanWords .slide:nth-of-type(${i})`, {y: -20}, {y: 20, ease: 'none', duration: 4.5}, 0)
    }
  }
*/


}, [])

  return ( 
      <section id="ActionSpeaksLouderThanWords">
        <div className="scrollwrapper">
          <h1 style={{width: titleWidth}}><span className="actionspeaks_title">{props.data.title}</span></h1>
          <h2 className="first">{props.data.slide1}</h2>
          <h2 className="second">{props.data.slide2}</h2>
          {props.items.map((ele, i) => (
              <div className="slide" key={i}>
                <Link to={`/companies/${ele.link_to_case.post_name}#howwehelped`}>
                  <LinkArrow className="linkarrow"/>
                  <div className="title">{ele.title}</div>
                  <p className="description mob">{ele.description}</p>
                </Link>
                <p className="description desk">{ele.description}</p>
              </div>
            ))}
        </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(ActionSpeaksLouderThanWords);

