import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import SectionHero from './Sections/1_SectionHero'
import SectionPeopleAtYourService from './Sections/2_SectionPeopleAtYourService'
import PartOfMondayMeetings from './Sections/3_PartOfMondayMeetings'
import HelpfullVentureDeveloper from './Sections/4_HelpfullVentureDeveloper'
import NoMatterTheChallenge from './Sections/5_NoMatterTheChallenge'
import PushingYourStartup from './Sections/6_PushingYourStartup'
import AlwaysOnDemand from './Sections/7_AlwaysOnDemand'
import Gallery from './Sections/8_Gallery'
import ActionSpeaksLouderThanWords from './Sections/9_ActionSpeaksLouderThanWords'
import StickyMenu from './Sections/StickyMenu'

function Operations(props) {
  const [wrapperHeight, setWrapperHeight] = useState(0);
  // const scrollLengths = [600,2000,5000,2000,3000,5000,4500]
  const scrollLengths = [300,500,2500,600,1000,600,600]
  
  // calculate & insert scrolllength : gallery
    let length = props.data.people.length * 300;
    scrollLengths.push(length)

  // calculate & insert scrolllength : outcome, cases
    length = 2500 + props.data.companys.length * 300;
    scrollLengths.push(length)

  useEffect(() => {
    let scrollLengthTotal = 0;
    scrollLengths.map((val) => scrollLengthTotal += val);  
    setWrapperHeight(scrollLengthTotal + window.innerHeight)
  }, [])

  const calculatedScrollStart = (sectionIndex) => {
    let val = 0;
    for (let i = 0; i < sectionIndex; i++) {
      val += scrollLengths[i];
    }

    return val
  }

  const scrub = 0.3;
  
  return (
  	props.data ? 
    <div className={`PageModule Operations mod${props.num}`} style={{height: wrapperHeight /*`${props.isMobileSize ? 'auto' : wrapperHeight}`*/}}>
        <div id="animation-container">
          <StickyMenu calculate={calculatedScrollStart} items={props.data.companys}/>
          <SectionHero scrub={scrub} start={0} length={scrollLengths[0]} data={{line1: props.data.header_headline_1, line2: props.data.header_headline_2}}/>
          <SectionPeopleAtYourService scrub={scrub} start={calculatedScrollStart(1)} length={scrollLengths[1]} data={{headline: props.data.peopleatyour_headline, text: props.data.peopleatyour_text}}/>
          <PartOfMondayMeetings scrub={scrub} start={calculatedScrollStart(2)} length={scrollLengths[2]} data={{line1: props.data.partofmonday_line1, line2: props.data.partofmonday_line2}}/>
          <HelpfullVentureDeveloper scrub={scrub} start={calculatedScrollStart(3)} length={scrollLengths[3]} data={{headline: props.data.helpfulventure_headline, text: props.data.helpfulventure_text}}/>
          <NoMatterTheChallenge scrub={scrub} start={calculatedScrollStart(4)} length={scrollLengths[4]} title={props.data.nomatterthe_headline}/>
          <PushingYourStartup scrub={scrub} start={calculatedScrollStart(5)} length={scrollLengths[5]} data={{headline: props.data.pushingyourstartup_headline, text: props.data.pushingyourstartup_text}}/>
          <AlwaysOnDemand scrub={scrub} start={calculatedScrollStart(6)} length={scrollLengths[6]} data={{headline: props.data.alwaysondemand_headline, text: props.data.alwaysondemand_text}}/>
          <Gallery scrub={scrub} items={props.data.people} start={calculatedScrollStart(7)} length={scrollLengths[7]}/>
          <ActionSpeaksLouderThanWords scrub={scrub} start={calculatedScrollStart(8)} length={scrollLengths[8]} items={props.data.companys} data={{title: props.data.outcomecases_title, slide1: props.data.outcomecases_slide1, slide2: props.data.outcomecases_slide2}}/>
    	</div>
    </div>
    : null
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(Operations);
