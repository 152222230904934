import React from 'react';
import './index.scss';

import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as MenuIcon} from './../../../assets/icons/plusicon.svg'

function TopMenu(props) {
  return (
  		props.initialPageHasLoaded ? 
	  		<div>
		  		<div className={`Topmenu left ${props.scrollDir == 'down' && props.scrollPosition > 200 ? 'hide' : 'show'}`}>
			    	{props.menu && props.menu.topmenu ? 
						props.menu.topmenu.left.map((ele, i) => (
			  	 			<div className="menuitem" key={i}><NavLink key={i} to={{pathname: "/" + ele.post_name, state: {fromTag: true}}} data-title={ele.post_title}>{ele.post_title}</NavLink></div>
							))
		    		: null}
		    	</div>
		    	<div className={`Topmenu right ${props.scrollDir == 'down' && props.scrollPosition > 200 ? 'hide' : 'show'}`}>
			    	{props.menu && props.menu.topmenu ? 
						props.menu.topmenu.right.map((ele, i) => (
			  	 			<div className="menuitem" key={i}><NavLink key={i} to={{pathname: "/" + ele.post_name, state: {fromTag: true}}} data-title={ele.post_title}>{ele.post_title}</NavLink></div>
							))
		    		: null}
		    	</div>
	    	</div>
    	: null
  );
}

export default connect((state) => ({
	menu: state.Globals.menu,
	showBanner: state.App.showBanner,
	scrollDir: state.App.scrollDir,
	scrollPosition: state.App.scrollPosition,
	initialPageHasLoaded: state.App.initialPageHasLoaded
}))(TopMenu)