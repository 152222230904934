import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'

import SEO from './../SEO'
import Footer from './../Footer'

import SinglePeopleHeader from './../PageModules/SinglePeopleHeader'
import SinglePeopleLove from './../PageModules/SinglePeopleLove'
import SingleNavigation from './../PageModules/SingleNavigation'
import ArchivePeopleThumb from "../PageModules/ArchivePeopleThumb";
import SingleContentRelated from "../PageModules/SingleContentRelated";

function SinglePeople(props) {
  useEffect(() => {
    // if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    // else window.scrollTo(0,0);
      console.log(props.apiData);
  }, [props.dataKey])

  return (
  	<div className={`page page-singlepeople${props.mainmenuIsOpen ? ' open' : ''} bg-${props.apiData.backgroundcolor} ${props.apiData.love?.images ? 'full' : 'single'}`}>
      <SEO dataKey={props.dataKey} title={props.apiData.post_title} description={props.apiData.meta_description}/>

      <SinglePeopleHeader name={props.apiData.post_title} title={props.apiData.title} image={props.apiData.portrait[0]} description={props.apiData.headertext} some={props.apiData.some} maincontent={props.apiData.love.text}/>
      <SinglePeopleLove data={props.apiData.love}/>
      {/*<SinglePeopleLove data={props.apiData.love}/>*/}
        { !props.apiData.hide_from_archive ? <SingleNavigation pages={props.apiData.next_prev} hasLoveSection={props.apiData.love?.images ? true : false}/> : null }


        <SingleContentRelated related={props.apiData.author_posts}/>
    </div>
  );
}

export default connect((state, ownProps) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
  return ({
	apiData: state.App.pageData[ownProps.dataKey].data,
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
  routerAction: state.router.action,
  meta: state.Globals.meta,
})
})(SinglePeople);
