import React, {useState, useEffect} from 'react';
import './7_AlwaysOnDemand.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import {connect} from 'react-redux'

import {ReactComponent as GearSmall} from './../../../../../assets/operations/gear_small.svg'
import {ReactComponent as GearLarge} from './../../../../../assets/operations/gear_large.svg'
import {ReactComponent as Stick} from './../../../../../assets/operations/stick_new.svg'

function AlwaysOnDemand(props) {

useEffect(() => {
  gsap.registerPlugin(ScrollTrigger)

  let scrollOptions = {
    mobile: {
      // mobile
        trigger: '#AlwaysOnDemand',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
    },
    desktop: {
      start: `${props.start} 0%`,
      end: "+="+props.length,
      // scrub: props.scrub,
      scrub: props.isMobileSize ? 'true' : props.scrub,
      onEnter: () => props.dispatch({
        type: 'SET_ACTIVE_OPERATIONS_SECTION',
        id: 3
      })
    }
  }

  let tl = gsap.timeline({
    // scrollTrigger: props.isMobileSize ? scrollOptions.mobile : scrollOptions.desktop
    scrollTrigger: scrollOptions.desktop
  })
    
  // opacity
    .to('#AlwaysOnDemand', {opacity: 1, ease: 'none', duration: 0.5}, 0)
    .to('#AlwaysOnDemand', {opacity: 0, ease: 'none', duration: 0.5}, 12.3)
  
  // position
    // if (!props.isMobileSize) 
      tl.fromTo('#AlwaysOnDemand .text', {y: 80}, {y: -80, ease: 'none', duration: 13.5}, 0)

  // jank - in 1
    tl.to('#AlwaysOnDemand .GearSmallSVG', {rotate: 4, ease: 'none', duration: 0.5}, 0)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: -4, ease: 'none', duration: 0.5}, 0)
    .to('#AlwaysOnDemand .StickSVG', {rotate: -25, x: '-=10', y: '-=28', ease: 'none', duration: 0.5}, 0)

  // jank - out 1
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: -10, ease: 'none', duration: 1}, 0.8)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: 10, ease: 'none', duration: 1}, 0.8)
    .to('#AlwaysOnDemand .StickSVG', {rotate: -0, x: '+=10', y: '+=28', ease: 'none', duration: 1}, 0.8)

  // jank - in 2
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: 4, ease: 'none', duration: 0.5}, 2.5)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: -4, ease: 'none', duration: 0.5}, 2.5)
    .to('#AlwaysOnDemand .StickSVG', {rotate: -25, x: '-=10', y: '-=28', ease: 'none', duration: 0.5}, 2.5)

  // jank - out 2
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: -10, ease: 'none', duration: 1}, 3.3)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: 10, ease: 'none', duration: 1}, 3.3)
    .to('#AlwaysOnDemand .StickSVG', {rotate: -0, x: '+=10', y: '+=28', ease: 'none', duration: 1}, 3.3)

  // jank - in 3
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: 4, ease: 'none', duration: 0.5}, 5)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: -4, ease: 'none', duration: 0.5}, 5)
    .to('#AlwaysOnDemand .StickSVG', {rotate: -25, x: '-=10', y: '-=28', ease: 'none', duration: 0.5}, 5)

  // jank - out 3
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: -10, ease: 'none', duration: 1}, 5.8)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: 10, ease: 'none', duration: 1}, 5.8)
    // .to('#AlwaysOnDemand .StickSVG', {rotate: -0, x: '+=10', y: '+=28', ease: 'none', duration: 1}, 5.8)

  // free running
    .to('#AlwaysOnDemand .GearSmallSVG', {rotate: 180, ease: 'none', duration: 6}, 6.8)
    .to('#AlwaysOnDemand .GearLargeSVG', {rotate: -180, ease: 'none', duration: 6}, 6.8)
    .to('#AlwaysOnDemand .StickSVG', {rotate: 50, x: '+=50', y: '+=58', ease: 'none', duration: 2}, 5.8)

}, [])

  return ( 
      <section id="AlwaysOnDemand" className="mechanics">
          <div className="col text">
            <div className="inner">
              <h2 className="title">{props.data.headline}</h2>
              <div className="content" dangerouslySetInnerHTML={{__html: props.data.text}}/>
            </div>
          </div>
          <div className="col graphics">
            <div className="inner">
              <GearSmall className="GearSmallSVG mechanicsSVG"/>
              <GearLarge className="GearLargeSVG mechanicsSVG"/>
              <Stick className="StickSVG mechanicsSVG"/>
            </div>
          </div>
      </section>
  );
}

export default connect(state => ({
  isMobileSize: state.Device.size.width <= 1050 ? true : false
}))(AlwaysOnDemand);
