import React, {useState, useEffect} from 'react'
import './StickyMenu.scss';

import {ReactComponent as LinkArrow} from './../../../../../assets/operations/linkarrow.svg'

import {connect} from 'react-redux'

function StickyMenu(props) {
	const menuItems = [
		{title: 'People at your service', anchor: 'SectionPeopleAtYourService', sectionId: 1, scrollOffset: 100},
		{title: 'Helpful Venture Developer', anchor: 'name-helpful-venture-developer', sectionId: 3, scrollOffset: 100},
		{title: 'Ways of working', anchor: 'name-pushing-your-startup-with-all-we-got', sectionId: 5, scrollOffset: 100},
		{title: 'Experts on demand', anchor: 'name-always-on-demand', sectionId: 6, scrollOffset: 100},
		{title: 'Outcome, Cases', anchor: 'name-action-speaks-louder-than-words', sectionId: 8, scrollOffset: 100, showsubmenu: true}
	]

	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		if (props.scrollPos >= window.innerHeight && !isVisible) setIsVisible(true)
		else if (props.scrollPos < window.innerHeight && isVisible) setIsVisible(false)
	}, [props.scrollPos])

	const scrollToSection = (sectionId, scrollOffset) => {
		// console.log('%cscrolling menu ' + anchor, 'background: lightblue');

		// let ele = document.getElementById(anchor);
		// let scrollPos = ele.offsetTop;
		// console.log(scrollPos);

		// console.log('scrollPos', scrollPos);

		let offset = props.calculate(sectionId) + scrollOffset;
		console.log(offset);
		window.scrollTo({top: offset, behavior: 'smooth'});
		
		// props.dispatch({
		// 	type: 'SET_SCROLL_POSITION',
		// 	scrollPosition: 200
		// })
	}

	const scrollToCase = (caseId) => {
		console.log(props.calculate(8)); // 7000
		let offset = props.calculate(8) + 1780 + 444 * caseId + 222;
		console.log(offset);
		window.scrollTo({top: offset, behavior: 'smooth'});
	}

	return(
		!props.isMobile ? 
			<div className={`StickyMenu ${props.scrollPos > 200 && props.scrollDir == 'down' ? 'show' : 'hide'}`}>
				{menuItems.map((ele, i) => (
					<div key={i} className={`menu-item ${props.activeMenuItem === i ? 'active' : ''}`}><LinkArrow className="activearrow"/>
						<span onClick={() => scrollToSection(ele.sectionId, ele.scrollOffset)}>{ele.title}</span>
						{ele.showsubmenu ?
							<div className="submenu">
								{props.items.map((item, caseId) => (
									<div className="item" key={item.title} onClick={() => scrollToCase(caseId)}>{item.title}</div>
								))}
							</div>
						: null}
					</div>
				))}
			</div>
			: null
	)
}

export default connect((state) => ({
	scrollPos: state.App.scrollPosition,
	isMobile: state.Device.isMobile,
	activeMenuItem: state.Operations.activeMenuItem,
	scrollDir: state.App.scrollDir
}))(StickyMenu)