import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'
import Btn from './../../Btn'

import {wrapString, MathUtils} from './../../../helpers'

import ScrollDownArrow from './../../ScrollDownArrow'


function SinglePeopleLove(props) {
  const headerImageOffset = useRef();
  const [imageOffsetTop, setImageOffsetTop] = useState(0)
  const [maxScroll, setMaxScroll] = useState(0); 
  const [minScroll, setMinScroll] = useState(0); 
  const [distanceToScroll, setDistanceToScroll] = useState(0); 
  const [currentImage, setCurrentImage] = useState({current: 0, prev: 0})
  // const [imageInterval, setImageInterval] = useState(null)

  const imageColRef = useRef();
  const textColRef = useRef();
  const stickyRef = useRef();
  const imagePosCurrent = useRef()
  const imagePosPrevious = useRef()
  const requestRef = useRef()
  const parentRef = useRef();

  const [easing, setEasing] = useState('0.1')

  const getImageOffsetTop = () => {
    // let imageWidth = (document.body.clientWidth-20)*0.33333333-20;
    let imageWidthInProcentage = props.windowSize.height <= 735 ? 0.25 : 0.33
    let imageWidth = (document.body.clientWidth-20)*imageWidthInProcentage-20;
    let rel = props.image.height/props.image.width;
    let offsetTop = window.innerHeight/2-imageWidth*rel/2;
    return offsetTop;
  }

  useEffect(() => {
    if (!props.isMobile && parentRef.current) {
      setTimeout(() => {
        // let imageWidth = (document.body.clientWidth-20)*0.33333333-20;
        // let rel = props.image.height/props.image.width;
        // let offsetTop = window.innerHeight/2-imageWidth*rel/2;
          let min = parentRef.current.offsetTop
          // console.log('singleloveHeight', singleloveHeight); 
          // let distToScroll = imageColRef.current.offsetHeight - stickyRef.current.offsetHeight;
          let distToScroll = parentRef.current.offsetHeight - window.innerHeight;
          let max = min + distToScroll;

          // let offset = getImageOffsetTop()
          // console.log(offset);

          setMinScroll(min)
          // setMinScroll(min)
          setMaxScroll(max)
          setDistanceToScroll(distToScroll)

          headerImageOffset.current = 0
          setImagePosFromTop();
      }, 50)
    }
  }, [props.windowSize, props.image])

  useEffect(() => {
    // start timer
    let imageInterval = setInterval(() => {
      setCurrentImage(prevState => {
        return {
          current: prevState.current === props.data?.images.length-1 ? 0 : prevState.current + 1,
          prev: prevState.current
        }
      })
    }, 3000)


    return () => {
      clearInterval(imageInterval);
    }
  }, [])

  const setImagePosFromTop = () => {
    let valToUse = 
      props.scrollPosition >= maxScroll ? distanceToScroll : 
      props.scrollPosition <= minScroll ? 0 : props.scrollPosition - minScroll
      imagePosCurrent.current = valToUse
  }

  const animateImage = () => {
    imagePosPrevious.current = MathUtils.lerp(imagePosPrevious.current, imagePosCurrent.current, easing)
    if (imageColRef.current) imageColRef.current.style.transform = `translate3d(0,${imagePosPrevious.current}px,0)`;
    // if (ele.length > 0) ele[0].style.transform = `translate3d(0,${imagePosPrevious.current}px,0)`;
    requestRef.current = requestAnimationFrame(animateImage)
  }

  useEffect(() => {
    if (!props.isMobile) setImagePosFromTop();
  }, [props.scrollPosition])

  useEffect(() => {
    if (!props.isMobile) {
      setTimeout(() => {
        setImagePosFromTop();
        imagePosPrevious.current = headerImageOffset.current;
        requestRef.current = requestAnimationFrame(animateImage);
        console.log('mounting single people', props.pathname);

        imagePosPrevious.current = headerImageOffset.current;
        imagePosCurrent.current = headerImageOffset.current;

        return () => cancelAnimationFrame(requestRef.current);
      }, 50)
    }
  }, [])

  // useEffect(() => {
  //   imagePosPrevious.current = headerImageOffset.current;
  //   imagePosCurrent.current = headerImageOffset.current;
  //   console.log('pathname changed', props.pathname);
  // }, [props.pathname])

  return (
    props.data?.images ? 
      <div className={`PageModule SinglePeopleLove`} ref={parentRef}>
          <div className="col col4 image" ref={imageColRef}>
            <div className="inner sticky" ref={stickyRef}>
              {props.data?.images.map((ele, key) => (
                <LazyImageComponent height={props.data?.images[key]?.sizes['large-height']} width={props.data?.images[key]?.sizes['large-width']} url={props.data?.images[key]?.sizes['large']} className={`${currentImage.current === key ? 'active' : currentImage.prev === key ? 'prev' : ''}`}/>
              ))}
            </div>
          </div>
          <div className="col col5 text" ref={textColRef}>
            <div className="inner section maintext" dangerouslySetInnerHTML={{__html: props.data?.text}}/>
      	  </div>
      </div>
      : null
  );
}

export default connect((state) => ({
  windowSize: state.Device.size,
  pageCanBeRevealed: state.App.pageCanBeRevealed,
  scrollPosition: state.App.scrollPosition,
  pathname: state.router.location.pathname,
  isMobile: state.Device.isMobile
}))(SinglePeopleLove);