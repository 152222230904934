import React, {Component, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'

import './index.scss';

import ApiFetcher from './ApiFetcher'

import BezierEasing from 'bezier-easing'

import NormalPage from './../NormalPage'
import SingleCases from './../SingleCases'
import SinglePeople from './../SinglePeople'
import SingleContent from './../SingleContent'

import NoMatch from './../NoMatch'
import SidebarFetcher from './../SidebarFetcher'


function Routes(props) {
	var easingIn = BezierEasing(0.9, 0, 0.1, 1);
	var easingInAlt = BezierEasing(0, 0.65, 0.2, 1);
	var easingOut = BezierEasing(0.55, 0, 1, 0.5);

	const onScroll = (e) => {
		if (props.scrollIsActive) {
			props.dispatch({
				type: 'SET_SCROLL_POSITION',
				scrollPosition: e.target.scrollTop
			})
		}
	}

	// useEffect(() => {
	// 	if (props.pageToShow) console.log('HER HER', props.pageToShow.component);
	// }, [props.pageToShow])



	return (
		<div className="loadwrapper">
			<Switch>
		 		{props.routes.map((ele, id) => {
		 			return(
						// <Route key={id} exact={ele.slug == ''  ? true : false} path={'/'+ele.slug} component={ApiFetcher}/>
						<Route key={id} exact={ele.exact} path={'/'+ele.slug} component={ApiFetcher}/>
						// <Route key={id}  path={'/'+ele.slug} component={ApiFetcher}/>
	 				)})}

				<Route path="*" component={NoMatch}/>
			</Switch>
			{!props.isMobile ? <div className="page-ghost" style={{height: props.pageHeight}}/> : null}
				{!props.useAnimation ? props.pageToShow ? 
					props.pageToShow.component === 'normalpage' ? <NormalPage dataKey={props.pageToShow.key}/> : 
					props.pageToShow.component === 'single-cases' ? <SingleCases dataKey={props.pageToShow.key}/> : 
					props.pageToShow.component === 'single-people' ? <SinglePeople key={props.pageToShow.key} dataKey={props.pageToShow.key}/> : 
					props.pageToShow.component === 'single-stuff' ? <SingleContent dataKey={props.pageToShow.key}/> : 
				// props.pageToShow.component === 'single-resources' ? <SingleResource dataKey={props.pageToShow.key}/> : 
				// props.pageToShow.component === 'single-events' ? <SingleEvent dataKey={props.pageToShow.key}/> : 
					null : null : 
				null}
		</div>
	);
}



export default connect((state) => ({
	useAnimation: false,
	routes: state.Routes.routes,
	pageToShow: state.App.pagesToShow[0],
	pagesToShow: state.App.pagesToShow,
	pageHeight: state.App.pageHeight,
	isMobile: state.Device.isMobile
}))(Routes)