import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

function Tag(props) {
  return (
    <Link to={`/content?cat=${props.slug}`}><li className="tag">{props.label}</li></Link>
  );
}

export default connect()(Tag);
