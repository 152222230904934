import {setDeviceToMobile, setDeviceSize} from './../actions/'
// import MobileDetect from 'mobile-detect';
import {isMobile} from 'react-device-detect';
import {store} from './configureStore'

export const InitDeviceAndScreenListener = () => {

	// let md = new MobileDetect(window.navigator.userAgent);
	_checkForDevicetype(store);
	window.addEventListener('resize', () => _checkForDevicetype(store));
}

const _checkForDevicetype = () => {
	// let md = new MobileDetect(window.navigator.userAgent);
	setDeviceToMobile(isMobile)
	store.dispatch(setDeviceSize(window.innerWidth, window.innerHeight))
}