import React, {useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {ReactComponent as ScrollIcon} from './../../../../assets/icons/icon_arrow_scroll.svg'

function SingleFaq({data}) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentInner = useRef();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    let height = isOpen ? 0 : contentInner.current.offsetHeight;
    setHeight(height)
  }

  return (
    <div className={`single ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
      <div className="title">{data.title}<ScrollIcon className="arrow"/></div>
      <div className="content" style={{height: height}}>
        <div className="content-inner" ref={contentInner} dangerouslySetInnerHTML={{__html: data.main_text}}/>
      </div>
    </div>
    )
}

function FAQ(props) {
  return (
  	props.data ? 
    <div className={`PageModule FAQ mod${props.num}`}>
        <div className="col col8">
          {props.data.rows.map((ele, i) => (
            <SingleFaq data={ele} key={i}/>
            ))}
    	</div>
    </div>
    : null
  );
}

export default connect()(FAQ);
