import {configureGlobals} from './configureGlobals'
import {configureRoutes} from './configureRoutes'
import {configureAnalytics} from './configureAnalytics'
import {configureScroll} from './configureScroll'
import {InitDeviceAndScreenListener} from './initDeviceAndScreen'

export const initSite = () => {
	configureRoutes();
	configureGlobals();
	configureAnalytics();
	configureScroll();
	InitDeviceAndScreenListener();
}