import React, {useRef, useEffect, useState} from 'react';
import {connect} from 'react-redux'

import {MathUtils} from './../../helpers'
import {store} from './../../config/configureStore'

import {setPageHeight} from './../../actions'

function SmoothScrollHandler(props) {
	const [easing, setEasing] = useState(0.1)
	var requestRef = useRef();

	// page scroll start
		var scrollPosCurrent = useRef();
		var scrollPosPrevious = useRef();
	// page scroll end

	// operations start
		const isOnOperationsPage = useRef()
	// operations end
	

	const shouldUseSmoothScroll = () => {
			isOnOperationsPage.current = document.getElementsByClassName('Operations').length === 1;

				var pageContainer = document.getElementsByClassName('page-normalpage')[0]
				if (pageContainer) {
					pageContainer.style.position = isOnOperationsPage.current || props.isMobile ? 'absolute' : 'fixed';
					// pageContainer.style.position = 'absolute'; // for testing
					pageContainer.style.top = `0`;
				}

				if (isOnOperationsPage.current) {
					var ele = document.getElementsByClassName('page')
				if (ele.length > 0) ele[0].style.transform = `translate3d(0,0,0)`;
				}

			// if (isOnOperationsPage.current && props.isMobile) updateElementsOnOperationsPage();
	}

	useEffect(() => {
		setPageHeight();
		shouldUseSmoothScroll();
	}, [props.screenSize, props.isMobile])

	useEffect(() => {
		if (props.pageCanBeRevealed) shouldUseSmoothScroll();
	}, [props.pageCanBeRevealed])

	useEffect(() => {
		// page scrolling
			scrollPosCurrent.current = props.scrollPosition

		// operations && mobile
			// if (isOnOperationsPage.current && props.isMobile) updateElementsOnOperationsPage();
	}, [props.scrollPosition])


	useEffect(() => {
		if (props.keyToShow) { // runs on every routechange

			// set pageheight
				setPageHeight();

			// init scroll on pageload & browserhistory
			    var destVal = 0;
			    if (props.routerAction === 'POP' && props.lastScroll) destVal = props.lastScroll.val;
    			else if (props.routerHash == '#founder') {
    				var destVal = document.getElementsByClassName('SingleCasesDatabox')[0].getBoundingClientRect().top - 140
    			} else if (props.routerHash == '#howwehelped') {
    				var destVal = document.getElementsByClassName('SingleCasesHowWeHelped')[0].getBoundingClientRect().top - 140
    			}

				scrollPosPrevious.current = destVal;
				scrollPosCurrent.current = destVal; 
				window.scrollTo(0, destVal)
		}
	}, [props.keyToShow])

	const updateElementsOnOperationsPage = () => {
			var operationsContainer = document.getElementsByClassName('page-normalpage')[0]
			if (operationsContainer) {
					operationsContainer.style.position = `absolute`;
					operationsContainer.style.top = `0`;
				}

		/*
		// mobile specific
			if (props.isMobile) {
				scrollPosPrevious.current = scrollPosCurrent.current;
			}

		// set fixed operationsContainer
			var operationsContainer = document.getElementById('animation-container')
			if (!props.isMobile) {
				if (operationsContainer) operationsContainer.style.transform = `translate3d(0,${scrollPosPrevious.current}px,0)`;					
			} else {
				console.log('is mobile');
				if (operationsContainer) {
					// operationsContainer.style.position = `sticky`;
					// operationsContainer.style.top = `0`;
				}
			}
			*/
	}

	const animatePage = (timeFrame) => {
		// animate window scroll, disable on operations
		if (!isOnOperationsPage.current) {
			scrollPosPrevious.current = MathUtils.lerp(scrollPosPrevious.current, scrollPosCurrent.current, easing)
			var ele = document.getElementsByClassName('page')
			if (ele.length > 0) ele[0].style.transform = `translate3d(0,${-scrollPosPrevious.current}px,0)`;
		}

		// animate operations
			// if (isOnOperationsPage.current) updateElementsOnOperationsPage();

		requestRef.current = requestAnimationFrame(animatePage)
	}

	useEffect(() => {
		// init variables
			scrollPosPrevious.current = 0;
			scrollPosCurrent.current = 0;
		
      // if on desktop, start animation
			if (!props.isMobile) {
				requestRef.current = requestAnimationFrame(animatePage);
				return () => cancelAnimationFrame(requestRef.current);
			}	
	}, [])

	return null;
}

export default connect((state) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
	return ({
		keyToShow: state.App.pagesToShow[0] ? state.App.pagesToShow[0].key : null,
	    scrollPosition: state.App.scrollPosition,
	    routerAction: state.router.action,
  		routerHash: state.router.location.hash,
	    lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
	    screenSize: state.Device.size,
	    pageCanBeRevealed: state.App.pageCanBeRevealed,
	    isMobile: state.Device.isMobile
	})
})(SmoothScrollHandler);
