import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'

import './index.scss';

function ScrollVisibilityComponent({children, vidId, className}) {
	const [isVisible, setIsVisible] = useState(false);
	const [isInViewport, setIsInViewport] = useState(false);

  return (
  	<VisibilitySensor partialVisibility={true} /*active={hasBeenInViewport ? false : true}*/ onChange={(isVisible) => {setIsInViewport(isVisible); if (isVisible) setIsVisible(true)}}>
  		<div className={`viewport-visibility-component ${isVisible ? 'visible' : 'hidden'} ${isInViewport ? 'in-viewport' : 'out-of-viewport'} ${className ? className : ''}`}>
    		{children}
    	</div>
    </VisibilitySensor>	
  );
}

export default ScrollVisibilityComponent;
