import paper from 'paper';
import {store} from './../../config/configureStore'

export default class MainMenu {
	constructor(pos) {
		// variables
			this.innerPath = '';

			this.easing = 'easeOutQuad';

			this.menuIsOpen = false;

			this.offsetY = 100;
			this.offsetX = 100;
			this.size = 200;
			this.resetPoints = [];

			this.animationTweens = [];
// 
			// this.colors = ['#FFD100', '#F4A2D4', '#85c8e7', '#2e78bc', '#1F1C37', '#E2DEC9', '#eb5757']
			this.colors = ['#F4A2D4']


		// initPath
			this.initResetPoints();
			this.initPath();

		// init redux
			store.subscribe(() => this.updateOnRedux(this));
	}

	update() {
		this.innerPath.smooth({ type: 'continuous' });
	}

	updateOnResize() {
		this.initResetPoints();
	}

	initResetPoints() {
		this.resetPoints = [
			[window.innerWidth+this.offsetX/2, -this.offsetY],
			[window.innerWidth+this.offsetX/2, window.innerHeight/2],
			[window.innerWidth+this.offsetX/2, window.innerHeight+this.offsetY],
			[window.innerWidth+this.offsetX, window.innerHeight+this.offsetY],
			[window.innerWidth+this.offsetX, window.innerHeight/2],
			[window.innerWidth+this.offsetX, -this.offsetY]
		];
	}

	initPath() {
		this.innerPath = new paper.Path({
			segments: this.resetPoints,
		    fillColor: this.getRandomColor(),
		    closed: true,
		    visible: false,
		    // selected: true
		});
	}

	openMenu() {
		this.menuIsOpen = true;

		this.resetPath();
		this.animateIn.option1();
	}

	hideMenu() {
		this.menuIsOpen = false;
		this.animateOut.option1();
	}

	animateIn = {
		option1: () => {
			this.animatePath.leftMiddle_left();

			setTimeout(() => {
				this.animatePath.leftEdges_left();
			}, 100)
		},
	}

	animateOut = {
		option1: () => {
			this.animatePath.leftEdges_right();

			setTimeout(() => {
				this.animatePath.leftMiddle_right();
			}, 50)

			setTimeout(() => {
				this.resetPath();
			}, 600)
		},
	}

	animatePath = {
		leftMiddle_left: () => {
			this.innerPath.visible = true;

			let tween = this.innerPath.tweenTo({
				'segments[1].point.x': -this.offsetX,
			}, {
				duration: 500,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		leftEdges_left: () => {
			let tween = this.innerPath.tweenTo({
				'segments[0].point.x': -this.offsetX,
				'segments[2].point.x': -this.offsetX
			}, {
				duration: 600,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		leftEdges_right: () => {
			let tween = this.innerPath.tweenTo({
				'segments[0].point.x': this.resetPoints[0][0],
				'segments[2].point.x': this.resetPoints[2][0]
			}, {
				duration: 400,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		},

		leftMiddle_right: () => {
			let tween = this.innerPath.tweenTo({
				'segments[1].point.x': this.resetPoints[1][0],
			}, {
				duration: 500,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		},

		topMiddle_up: () => {
			this.innerPath.visible = true;

			let tween = this.innerPath.tweenTo({
				'segments[1].point.y': -this.offsetY,
			}, {
				duration: 500,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		topSides_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[0].point.y': -this.offsetY,
				'segments[2].point.y': -this.offsetY
			}, {
				duration: 600,
				easing: this.easing
			})

			this.animationTweens.push(tween)
		},

		bottomSides_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[3].point.y': -this.offsetY/2,
				'segments[5].point.y': -this.offsetY/2
			}, {
				duration: 400,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		},

		bottomMiddle_up: () => {
			let tween = this.innerPath.tweenTo({
				'segments[4].point.y': -this.offsetY/2,
			}, {
				duration: 500,
				easing: 'easeInQuad'
			})

			this.animationTweens.push(tween)
		}
	}

	resetPath () {
		for (let i = 0 ; i < this.animationTweens.length; i++) {
			this.animationTweens[i].stop();
		}
		
		this.innerPath.visible = false;
		this.innerPath.fillColor = this.getRandomColor();

		for (let i = 0; i < this.resetPoints.length; i++) {
			this.innerPath.segments[i].point.x = this.resetPoints[i][0];
			this.innerPath.segments[i].point.y = this.resetPoints[i][1];
		}
	}

	getRandomColor() {
		return this.colors[Math.floor(Math.random()*this.colors.length)]
	}

	updateOnRedux(_this) {
		let menuIsOpenStore = store.getState().Globals.mainmenuIsOpen;

		if (menuIsOpenStore) {
			if (!_this.menuIsOpen) _this.openMenu();
		} else {
			if (_this.menuIsOpen) _this.hideMenu();
		}
	}
}