import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import App from './App.js' 
import Routes from './Routes.js' 
import Globals from './Globals.js' 
import Device from './Device.js' 
import Cursor from './Cursor.js'
import Operations from './Operations.js'

export default (history) => combineReducers({
	router: connectRouter(history),
	App: App,
	Routes: Routes,
	Globals: Globals,
	Device: Device,
	Cursor: Cursor,
	Operations: Operations
})