import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'
import LazyImageComponent from './../../LazyImageComponent'

import Tag from './../Parts/Tag'

function ArchiveContentThumb(props) {
  return (
  	<div className={`inner ArchiveContentThumb col col${props.width}`}>
      <div className="img-wrapper">
        <Link to={`/content/${props.post_name}`}>
            {props.image_thumb ? 
              <LazyImageComponent height={props.image_thumb.sizes['medium_large-height']} width={props.image_thumb.sizes['medium_large-width']} url={props.image_thumb.sizes.medium_large}/>
            : null}
        </Link>
      </div>
      <div className="info">
        <h6 className="title">{props.post_title}</h6>
        {props.tags ? <ul className="tags">
          {props.tags.map((ele, i) => (
            <Tag key={i} slug={ele.slug} label={ele.name}/>
          ))}
        </ul> : null}
      </div>
    </div>
  );
}

export default connect()(ArchiveContentThumb);
