import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ColorBoxWrapper from './../ColorBoxWrapper'

import {ReactComponent as QuoteIcon} from './../../../../assets/icons/icon_quote.svg'

function SingleCasesQuote(props) {
  return (
    props.data?.text ?
      <div className={`PageModule SingleCasesQuote`}>
        <ColorBoxWrapper color={props.data.backgroundcolor}>
          <div className="col col12">
            <div className="parallaxcontainer" data-parallaxamount={50} data-parallaxspeed={1}>
            	<div className="inner">
    	          <QuoteIcon/>
    	          <h1 className="quote">{props.data.text}</h1>
                <img src={props.data.person_image.sizes?.thumbnail} className="portrait"/>
    	          <div className="name">{props.data.person_name}</div>
                <div className="title">{props.data.person_title}</div>
    	          <div className="title">{props.data.person_company}</div>
            	</div>
            </div>
          </div>
        </ColorBoxWrapper>
      </div>
    : null
  );
}

export default connect()(SingleCasesQuote);
