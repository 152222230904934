import React from 'react';
import './index.scss';

import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

function ArchiveFilter(props) {
  return (
  	<div className={`ArchiveFilter col col${props.width}`}>
      <div className="title">{props.title}</div>
      <div className="filter-tags">
      <Link className={`filter-tags-single ${props.currentCat == null ? 'active' : ''}`} to={{search: ``}}>All</Link>
        {props.filterElements.map((ele, i) => (
          <Link key={i} className={`filter-tags-single ${props.currentCat == ele.slug ? 'active' : ''}`} to={{search: `cat=${ele.slug}`}}>{ele.name}</Link>
          ))
        }
      </div>
    </div>
  );
}

export default ArchiveFilter
