import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import {Link} from 'react-router-dom'
import './index.scss';
import EmbeddedVideoComponent from './../../EmbeddedVideoComponent'

function VideoGrid({data}) {
	var parse = require('html-react-parser');
  return (
  	<div className={`PageModule`}>
			<div className={`EmbedVideoGridWrapper col col12`}>
			{
				data.videos && data.videos.map((item, i) => (
					item['embed_url'] && <EmbeddedVideoComponent iframe={item.embed_url} link={item.link_to} title={item.title} className={`_col _col6`}/>
				))
			}
			</div>
		</div>
  );
}

export default VideoGrid;
