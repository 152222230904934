import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ColorBoxWrapper from './../ColorBoxWrapper'
import LazyImageComponent from './../../LazyImageComponent'
import Btn from './../../Btn'

import {makePagelinkUrl} from './../../../helpers'

function LinkWithImage(props) {
  // console.log(props);
  return (
  	props.data ? 
    <div className={`PageModule LinkWithImage mod${props.num}`}>
      <ColorBoxWrapper color={props.data.backgroundcolor}>
        <div className="col col4 image">
          <div className="parallaxcontainer" data-parallaxamount={props.isMobile ? 20 : 200} data-parallaxspeed={1}>
            <div className="inner">
              <LazyImageComponent height={props.data.image.sizes['medium_large-height']} width={props.data.image.sizes['medium_large-width']} url={props.data.image.sizes['medium_large']} imgClassName="parallax-img"/>
            </div>
          </div>
    	  </div>
        <div className="col col5 text">
          <div className="inner">
              <h1 className="title">{props.data.title}</h1>
              <p className="description">{props.data.description}</p>
              <Btn url={props.data.link_type == 'internal' ? makePagelinkUrl(props.data.page[0]) : props.data.url} label={props.data.label} type={props.data.link_type}/>
            </div>
        </div>
      </ColorBoxWrapper>
    </div>
    : null
  );
}

export default connect(state => ({
  isMobile: state.Device.isMobile
}))(LinkWithImage);
