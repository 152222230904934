import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent';
import EmbeddedVideoComponent from './../../EmbeddedVideoComponent'

import {convertHTMLStringToReact} from './../../../helpers'


function SingleCasesHowWeHelped(props) {
  return (
    props.data.length > 0 ?
      <div className={`PageModule SingleCasesHowWeHelped`}>
          <div className={`col col4 title`}>
            <h2>We helped {props.title} with</h2>
          </div>

          <div className="container">
          {props.data?.map((ele, i) => (
              <section className={`${ ele.video ? 'has-video': 'col6'} col help-section` } key={i}>
                <h3 className="title">{ele.title}</h3>
                <div className={`${ ele.video ? 'col6': ''} text`} dangerouslySetInnerHTML={{__html: ele.text}}/>
                {ele.video ?
                <div className="video col6">
                  <EmbeddedVideoComponent iframe={ele.video}/>
                </div>
                : null
              }
              </section>
          )) }
          </div>


      </div>
    : null
  );
}

export default connect()(SingleCasesHowWeHelped);
