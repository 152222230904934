import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import ScrollDownArrow from './../../ScrollDownArrow'

import {wrapString} from './../../../helpers'

function Pageheader(props) {
  return (
  	props.data ? 
      <div className={`PageModule Pageheader mod${props.num} ${props.pageCanBeRevealed ? 'reveal' : ''}`}>
          <div className="col col12">
            <div className={`title ${!props.data.manchet ? 'alt' : ''}`}>
              <h1 data-title={props.data.title_line1}>{props.data.title_line1}</h1>
              <h1 data-title={props.data.title_line2}>{props.data.title_line2}</h1>
            </div>
          </div>
          {props.data.manchet ? 
            <div className="col col4 left">
              <h4 className="manchet">{props.data.manchet}</h4>
        	  </div>
          : null}
          <div className={`${props.data.manchet ? 'col6' : 'col12'} col right`}>
            <p className={`description ${!props.data.manchet ? 'alt' : ''}`}>{props.data.description}</p>
          </div>
          <ScrollDownArrow/>
      </div>
    : null
  );
}

export default connect(state => ({
  pageCanBeRevealed: state.App.pageCanBeRevealed
}))(Pageheader);
