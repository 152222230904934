import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link, useLocation} from 'react-router-dom'
import ArchiveContentThumb from './../ArchiveContentThumb'
import {getApiBase} from './../../../helpers'

function SelectedContent(props) {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentCat, setCurrentCat] = useState(null);
  const initialLoad = useRef(true);

  let thumbWidth = Array(4,5,6);

  return (
  	props.moduleData ?
	    <div className={`PageModule SelectedContent mod${props.num}`}>
        {props.moduleData.posts.map((ele, i) => (
          <ArchiveContentThumb key={i} post_name={ele.post_name} image_thumb={ele.image_thumb} post_title={ele.post_title} tags={ele.tags} width={thumbWidth[i]}/>
          ))}
	    </div>
    : null
  );
}

export default connect()(SelectedContent);
