import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

function Contact(props) {
  return (
  	props.data ? 
      <div className={`PageModule Contact mod${props.num} ${props.pageCanBeRevealed ? 'reveal' : ''}`}>
          <div className="col col12">
            <div className="title">
              <h1 data-title={props.data.header}>{props.data.header}</h1>
            </div>
            <h4 className="address-title">Address</h4>
            <div className="address" dangerouslySetInnerHTML={{__html: props.data.address}}/>
            <div className="links">
              {props.data.phone ? <a className="item" href={`tel:${props.data.phone}`}>{props.data.phone}</a> : null}
              <a className="item" href={`mailto:${props.data.email}`}>{props.data.email}</a>
              <a className="item" href={props.data.maps.link} target="_blank">{props.data.maps.label}</a>
            </div>
          </div>
      </div>
    : null
  );
}

export default connect(state => ({
  pageCanBeRevealed: state.App.pageCanBeRevealed
}))(Contact);
